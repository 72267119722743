import React, { SVGProps } from "react";

import {
    TouchableOpacity, 
    TouchableOpacityProps,
    ViewStyle
} from "react-native";

import AppTheme from "../../utils/Theme";
import SVGIconButton from "./SVGIconButton";
import { default as icDismiss } from "../../assets/svg/icons/solid/times.svg";

interface CloseButtonProps extends TouchableOpacityProps {
    color?: string
}

const CloseButton = (props:CloseButtonProps) => {

    const appTheme: AppTheme = new AppTheme();
    const { color = appTheme.navigationColor , ...restProps } = props;

    return <SVGIconButton Icon={icDismiss} color={color} {...restProps} />
}

export default CloseButton;