import { default as IcBack } from "../assets/svg/lili/ic_back_arrow.svg";
import { default as IcChevronDown } from "../assets/svg/icons/solid/chevron-down.svg";
import { default as IcChevronLeft } from "../assets/svg/icons/solid/chevron-left.svg";
import { default as IcChevronRight } from "../assets/svg/icons/solid/chevron-right.svg";
import { default as IcClose } from "../assets/svg/icons/solid/times.svg";
import { default as IcDev } from "../assets/svg/icons/solid/dev.svg";
import { default as IcDocument } from "../assets/svg/lili/ic_pdf.svg";
import { default as IcDraw } from "../assets/svg/lili/ic_draw.svg";
import { default as IcFilm } from "../assets/svg/icons/solid/film.svg";
import { default as IcHeadphones } from "../assets/svg/lili/ic_audio_book.svg";
import { default as IcLiliStar } from "../assets/svg/lili/ic_star.svg";
import { default as IcOrigami } from "../assets/svg/lili/ic_origami.svg";
import { default as IcQuoteLeft } from "../assets/svg/icons/solid/quote-left.svg";
import { default as IcQuoteRight } from "../assets/svg/icons/solid/quote-right.svg";
import { default as IcPlay } from "../assets/svg/icons/solid/play.svg";
import { default as IcSearch } from "../assets/svg/lili/ic_search.svg";
import { default as IcStar } from "../assets/svg/icons/solid/star-of-life-solid.svg";
import { default as IcTapuscrit } from "../assets/svg/lili/ic_tapuscrit.svg";
import { default as IcPaperclip } from "../assets/svg/icons/solid/paperclip-solid.svg";
import { default as IcTheater } from "../assets/svg/lili/ic_play.svg";

export { 
    IcBack,
    IcChevronDown,
    IcChevronLeft,
    IcChevronRight,
    IcClose,
    IcDev,
    IcDocument,
    IcDraw,
    IcFilm,
    IcHeadphones,
    IcLiliStar,
    IcOrigami,
    IcQuoteLeft,
    IcQuoteRight,
    IcPaperclip,
    IcPlay,
    IcSearch,
    IcStar,
    IcTapuscrit,
    IcTheater
};