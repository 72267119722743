import React, { useEffect, useRef, useState } from 'react';
import { View, Animated, SafeAreaView, TouchableOpacity, Platform } from 'react-native';

import AppTheme from '../../utils/Theme';
import Image from "../../designSystem/OTBImage";

import Style from '../../utils/Style';

// SVG
import SVGView from "../../utils/SvgView";
import { default as IcTimes } from "../../assets/svg/icons/solid/times.svg";

/**
 * Player Audio
 */
import AudioPlayer from '../../../specific/utils/AudioPlayer/AudioPlayer';
import LiliAirPlayButton from '../../../specific/utils/AudioPlayer/AirPlayButton';
import { getUrlsToUse } from '../../utils/LocalStorage';

const ActivityPlayer = (props: any) => {

    const [stopRequest, setStopRequest] = useState(false);
    const [urls, setUrls] = useState<string[] | undefined>(undefined);
    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

    const audioPlayerRef = useRef(null);

    const appTheme: AppTheme = new AppTheme();

    let imageUrl = props.image;
    if (imageUrl === undefined) {
        if (props.localImage !== undefined) {
            imageUrl = props.localImage;
        } else if (props.remoteImage !== undefined) {
            imageUrl = { uri: props.remoteImage };
        }
    }
    const { percentageForCompletion = 70 } = props;

    const actionColor = appTheme.darkBlue;
    let innerViewBgColor = appTheme.white;
    const fullPlayerWidth = appTheme.getBlockWidth() - appTheme.pixelPerfect(40);
    const viewWidth = appTheme.getFlexDirectionForSplitScreen() === "row" ? fullPlayerWidth / 2 : fullPlayerWidth;
    let activityHeaderHeight = viewWidth * 108 / 144;
    const playerHeight = appTheme.getFlexDirectionForSplitScreen() === "row" ? activityHeaderHeight : appTheme.pixelPerfect(200);
    const borderRadius = appTheme.pixelPerfect(20);

    let viewHeight = playerHeight;
    if (appTheme.getFlexDirectionForSplitScreen() === "column") {
        viewHeight = activityHeaderHeight + playerHeight;
        if (viewHeight > appTheme.getFullAppHeight()) {
            viewHeight = appTheme.getFullAppHeight();
            activityHeaderHeight = viewHeight - playerHeight;
        }
    }

    useEffect(() => {
        checkUrls();
    }, [props]);

    useEffect(() => {
        if (urls !== undefined) {
            setViewDidAppear(true);
        }
    }, [urls]);

    const onViewWillAppear = () => {
        checkUrls();
    }

    const checkUrls = async () => {
        if (props.urls !== undefined) {
            const urlsToUse: string[] = await getUrlsToUse({ urls: props.urls, activityKey: props.activityKey });
            setUrls(urlsToUse);
        }
    }

    // Audio View
    const onPlayerEnd = () => {
        if (props.onPlayerEnd !== undefined) {
            props.onPlayerEnd();
        } else {
            askToCloseView();
        }
    }

    const onActivityCompletedForGamification = () => {
        if (props.onActivityCompleted !== undefined) {
            props.onActivityCompleted();
        }
    }

    const renderMainView = () => {
        console.log(stopRequest);
        return <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), marginEnd: appTheme.pixelPerfect(30) }} />
            <AudioPlayer
                ref={audioPlayerRef}
                size={140}
                onActivityCompleted={onActivityCompletedForGamification}
                percentageForCompletion={percentageForCompletion}
                onChangeUrl={onChangeUrl}
                onPlayerEnd={onPlayerEnd}
                stopRequest={stopRequest}
                autoLaunch={true}
                color={props.color}
                urls={urls}
                title={props.title !== undefined ? props.title : "Lili"}
                image={props.notificationImage !== undefined ? props.notificationImage : props.image} />
            <View style={{ marginStart: appTheme.pixelPerfect(30) }}>
                <LiliAirPlayButton activeTintColor={props.color} tintColor={props.color} />
            </View>
        </View>;
    }

    const onChangeUrl = () => {
        
    }

    const askToCloseView = () => {
        setStopRequest(true);
        if ((audioPlayerRef !== undefined) && (audioPlayerRef !== null)) {
            if ((audioPlayerRef.current !== undefined) && (audioPlayerRef.current !== null)) {
                if ((audioPlayerRef.current.onStopRequest() !== undefined) && (audioPlayerRef.current.onStopRequest() !== null)) {
                    audioPlayerRef.current.onStopRequest();
                }
            } 
        }
        closeView();
    }

    const closeView = () => {
        // Appel de la fonction close view dans la modal
        if (props.togglePlayer !== undefined) {
            props.togglePlayer();
        }
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewWillAppear} style={{ flex: 1, width: appTheme.getBlockWidth() }}>
                <View style={{ position: 'absolute', bottom: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth(), height: viewHeight, alignItems: "center" }} />
            </View>
        }
        return (
            <SafeAreaView style={{ flex: 1, width: appTheme.getBlockWidth() }}>
                <Animated.View style={{ position: 'absolute', bottom: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth(), height: viewHeight, alignItems: "center" }}>
                    <View style={[Style.fullViewShadowed, { flexDirection: appTheme.getFlexDirectionForSplitScreen(), width: fullPlayerWidth, height: viewHeight, borderRadius, alignItems: 'center', backgroundColor: innerViewBgColor }]}>
                        <Image source={imageUrl} style={{ width: viewWidth, height: activityHeaderHeight, borderRadius }} />
                        <View style={{ width: viewWidth, height: playerHeight, alignItems: "center", justifyContent: "center" }}>
                            {renderMainView()}
                        </View>
                        <TouchableOpacity
                            style={{ position: 'absolute', right: appTheme.pixelPerfect(10), top: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), justifyContent: 'center', alignItems: 'center', backgroundColor: appTheme.white }}
                            onPress={askToCloseView} >
                            <SVGView Component={IcTimes} width={appTheme.pixelPerfect(24)} height={appTheme.pixelPerfect(24)} color={actionColor} />
                        </TouchableOpacity>
                    </View>
                </Animated.View>
            </SafeAreaView >
        )
    }

    return getContent();
}

export default ActivityPlayer;
