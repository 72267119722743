module.exports = {
  "name": "lili",
  "version": "3.7",
  "build": "659",
  "description": "Lili Web App created with React Native Web",
  "main": "index.tsx",
  "author": "Axel de Sainte Marie (axel@lili.cool)",
  "license": "MIT",
  "private": true,
  "devDependencies": {
    "parcel-bundler": "^1.12.5",
    "typescript": "^5.8.2"
  },
  "dependencies": {
    "@adjustcom/adjust-web-sdk": "5.5.0",
    "@amplitude/analytics-browser": "2.11.1",
    "@fseehawer/react-circular-slider": "^2.7.0",
    "@ramonak/react-progress-bar": "^5.3.0",
    "@react-native-async-storage/async-storage": "1.17.0",
    "@stripe/react-stripe-js": "2.1.0",
    "@stripe/stripe-js": "1.52.1",
    "@types/react": "^19.0.10",
    "@types/react-dom": "^19.0.4",
    "@types/react-native": "^0.73.0",
    "appwrite": "10.1.0",
    "base-64": "^1.0.0",
    "borodindk-react-js-pager": "2.0.1",
    "core-js": "^3.41.0",
    "draft-js": "^0.11.7",
    "firebase": "10.11.1",
    "framer-motion": "10.12.10",
    "https-agent": "^1.0.0",
    "i18n-js": "^3.8.0",
    "md5": "^2.3.0",
    "papaparse": "^5.5.2",
    "react": "^19.0.0",
    "react-alert": "7.0.3",
    "react-calendar": "5.0.0",
    "react-cas-client": "1.1.0",
    "react-device-detect": "2.2.2",
    "react-dom": "^19.0.0",
    "react-flipcard": "0.2.1",
    "react-gradient": "1.0.2",
    "react-image-file-resizer": "0.4.8",
    "react-modal": "3.16.1",
    "react-native": "*",
    "react-native-svg-web": "1.0.9",
    "react-native-web": "^0.19.13",
    "react-player": "^2.16.0",
    "react-player-circle-controls": "^0.3.0",
    "react-router-dom": "6",
    "react-select": "5.8.0",
    "react-use-audio-player": "^4.0.2",
    "react-web-circular-slider": "^2.0.14",
    "shorthash": "^0.0.2",
    "smoothscroll-polyfill": "^0.4.4",
    "type-detect": "^4.1.0"
  },
  "scripts": {
    "postinstall": "npx patch-package",
    "clean": "rimraf dist && rm -rf .cache",
    "start": "parcel serve ./public/index.html",
    "build": "parcel build ./public/index.html --no-cache --no-source-maps",
    "sharedlink": "yarn clean && rm -r ./src/shared && ln -s ../../jidokapp_core_mobile/app/shared ./src/shared",
    "sharedcopy": "yarn clean && rm -r ./src/shared && cp -r ../jidokapp_core_mobile/app/shared ./src/shared",
    "web": "yarn sharedlink && parcel serve ./public/index.html",
    "prod": "yarn sharedcopy && parcel serve ./public/index.html",
    "publish:centralperk": "firebase use lili-c7853 && firebase deploy --only hosting:centralperk && firebase use lili-dev-608f0",
    "publish:prod": "firebase use lili-c7853 && firebase deploy --only hosting:app && firebase use lili-dev-608f0",
    "publish:staging": "firebase use lili-dev-608f0 && firebase --config=./firebase.json deploy --only hosting:staging"
  },
  "alias": {
    "react-native": "react-native-web",
    "react-native-svg": "react-native-svg-web"
  }
}
;