import React from "react";

import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity
} from 'react-native';

import ReactPlayer from "react-player";

import AppTheme from "../../../shared/utils/Theme";

import SVGView from "../../../shared/utils/SvgView";
import { default as icDismiss } from "../../../shared/assets/svg/icons/solid/times.svg";


const LiliVideoPlayer = (props: any) => {

    const appTheme: AppTheme = new AppTheme();

    // MainOptions
    const { url, } = props;

    const closeTheDocument = () => {
        if (props.closeTheDocument !== undefined) {
            props.closeTheDocument();
        }
    }

    const onBuffer = () => {

    }

    const onError = () => {

    }

    const onEnd = () => {
    }

    return (
        <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), justifyContent: "center", alignItems: "center", backgroundColor:appTheme.white }}>
            <ReactPlayer
                controls={true}
                playing={true}
                url={url}
                width={appTheme.getBlockWidth()}
                height={appTheme.getBlockWidth()} />
            <TouchableOpacity onPress={closeTheDocument} style={{ position:"absolute", top:appTheme.pixelPerfect(10), right:appTheme.pixelPerfect(10), backgroundColor: appTheme.darkBlue, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center" }}>
                <SVGView Component={icDismiss} width={appTheme.pixelPerfect(20)} height={appTheme.pixelPerfect(20)} color={appTheme.white} />
            </TouchableOpacity>
        </View>
    )
}

export default LiliVideoPlayer;

const styles = StyleSheet.create({
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
});