import { Adjust } from "../../../specific/services/Tracking/Adjust";

export const getTrackingTransparencyStatus = () => {
   Adjust.getAppTrackingAuthorizationStatus((status) => {
   });
}

export const requestTrackingTransparencyStatus = () => {
    Adjust.requestAppTrackingAuthorization((status) => {

    })
}
