import React, { useEffect, useState } from "react";

import {
    Modal,
    Platform,
    Text,
    View
} from "react-native";
import PDFViewer from "../../../../specific/components/PDF/PDFViewer";
import Activity from "../../../models/Activity";
import { getLanguage } from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";
import { trackEvent, analyticsEventActivityLaunch, propertiesEventContent, propertiesEventAccess, propertiesEventCategory, propertiesEventSection, propertiesEventName, analyticsEventActivityCompleted, propertiesEventProfile, analyticsEventWorkshopLaunched, analyticsEventWorkshopCompleted } from "../../../services/Tracking/Analytics";
import { onActivityCompleted } from "../../../../specific/services/Specific_Database";
import User from "../../../data/user/User";
import { getUrlToUse } from "../../../utils/LocalStorage";
import RoundIconButton from "../../../designSystem/RoundIconButton";
import PremiumButton from "../../../designSystem/PremiumButton";
import { getAppTarget } from "../../../../specific/utils/Navigation/Host";
import { getDocumentUrlForMediaId } from "../../../../specific/services/AppWrite/AppWriteDatabase";
import { IcDocument } from "../../../utils/Icons";

interface ActivityCellDocProps {
    activity: Activity,
    context: string,
    categoryAnalyticsKey:string,
    sectionAnalyticsKey:string,
    color: string,
    width: number,
    componentId: string,
    title:string,
    isEnable?: boolean,
    starting_point?: "superpower" | "section"
}

const ActivityCellDoc = (props: ActivityCellDocProps) => {
    const { activity, context, categoryAnalyticsKey, sectionAnalyticsKey, color, width, isEnable, componentId = "", title, starting_point="superpower" } = props;

    const appTheme: AppTheme = new AppTheme();
    const user:User = User.getInstance();

    const [launchDocument, setLaunchDocument] = useState(false);
    const [documentUrl, setDocumentUrl] = useState("");
    const [startTime, setStartTime] = useState(0); // On regarde à quel moment le PDF est ouvert pour la gamification

    useEffect(() => {
        if (documentUrl.length > 0) {
            setStartTime(new Date().getTime());
            setLaunchDocument(true);
        }
    }, [documentUrl]);

    const openTheDocument = async () => {
        trackEvent(analyticsEventActivityLaunch, {
            [propertiesEventCategory]: categoryAnalyticsKey,
            [propertiesEventContent]: "doc",
            [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
            [propertiesEventName]: activity.analytics_key,
            "starting_point":starting_point
        });
        if (documentUrl.length > 0) {
            setLaunchDocument(true);
        } else {
            let pdfUrl = activity.document.file[getLanguage()];
            const appTarget = getAppTarget();
            if (appTarget !== "public") {
                pdfUrl = getDocumentUrlForMediaId({media_id:activity.key});
            }
            pdfUrl = await getUrlToUse({url:pdfUrl, activityKey: activity.key});
            setDocumentUrl(pdfUrl);
        }
    }

    const closeTheDocument = () => {
        setLaunchDocument(false);
        const timeOfLeaving = new Date().getTime();
        const readingTime = timeOfLeaving - startTime;
        if (readingTime > 9999) {
            onActivityCompleted({
                activityKey: activity.key,
                sectionKey: activity.sectionKey,
                categoryKey: activity.categoryKeys[0],
                iteration: 1,
                detail: activity.sectionKey === 'grow' ? 'philo_notebook' : '',
                menu: ""
            });

            trackEvent(analyticsEventActivityCompleted, {
                [propertiesEventCategory]: categoryAnalyticsKey,
            [propertiesEventContent]: "doc",
            [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
            [propertiesEventName]: activity.analytics_key,
            "starting_point":starting_point
            });
        }
    }

    const actionButton = isEnable === true ? <RoundIconButton onPress={openTheDocument} iconName={IcDocument} backgroundColor={color} /> : <PremiumButton componentId={componentId} />;

    return <View style={{ width, alignItems: "center", marginTop: appTheme.pixelPerfect(10) }}>
        {actionButton}
        <Modal animationType="slide" visible={launchDocument} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: color + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, justifyContent: "flex-end", backgroundColor: color + "80", width: appTheme.getFullAppWidth() }}>
                    <PDFViewer
                        title={activity.title[getLanguage()]}
                        pdfUrl={documentUrl}
                        color={color}
                        sectionId={activity.sectionKey}
                        closeTheDocument={closeTheDocument} />
                </View>
            </View>
        </Modal>
    </View>
}

export default ActivityCellDoc;