import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";

import {
    TouchableOpacity,
    View
} from 'react-native';

import AppTheme from "../../../shared/utils/Theme";

import "./AudioPlayer.css"

import SVGView from "../../../shared/utils/SvgView";
import { default as IcPlay } from "../../../shared/assets/svg/icons/solid/play.svg";
import { default as IcPause } from "../../../shared/assets/svg/icons/solid/pause.svg";

import CircularSlider from '@fseehawer/react-circular-slider';

const AudioPlayer = forwardRef((props: any, ref) => {

    const audioObject: HTMLAudioElement = useRef(new Audio()).current;

    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [songIndex, setSongIndex] = useState(0);
    const { urls, image, percentageForCompletion = 75, gamification = true, displayView = true, onPlayerEnd, onChangeUrl } = props;
    const [position, setPosition] = useState(-1);


    const stopThePlayer = () => {
        audioObject.pause();
        audioObject.currentTime = 0;
        audioObject.remove();
        audioObject.oncanplay = () => {};
    }
    
    useImperativeHandle(ref, () => ({
        onStopRequest: () => {
            stopThePlayer();
        }
    }));

    useEffect(() => {
        if (props.stopRequest === true) {
            stopThePlayer();
        }
    }, [props.stopRequest]);

    const onAudioPlayerEnd = () => {
        if (songIndex + 1 < urls.length) {
            setSongIndex(songIndex + 1);
        } else {
            if (onPlayerEnd !== undefined) {
                onPlayerEnd();
            }
        }
    };

    const togglePlayPause = () => {
        if (isPlaying === true) {
            audioObject.pause();
        } else {
            audioObject.play();
        }
        setIsPlaying(!isPlaying);
    }

    const onPlayerPlay = () => {
        setCircularPosition();
        setIsPlaying(true);
    }

    const onCanPlay = () => {
        audioObject.play();
    }

    useEffect(() => {
        if (songIndex > -1) {
            audioObject.onended = onAudioPlayerEnd;
            audioObject.onplay = onPlayerPlay;
            audioObject.src = urls[songIndex];
            audioObject.oncanplay = onCanPlay;
        }
    }, [songIndex]);

    useEffect(() => {
        if (position > -1) {
            setTimeout(() => {
                if (isPlaying === true) {
                    setCircularPosition();
                }
            }, 1000);
        }
    }, [position]);

    const appTheme: AppTheme = new AppTheme();
    let audioPlayerSize: number = 100;
    if (props.size !== undefined) {
        audioPlayerSize = props.size;
    };

    const setCircularPosition = () => {
        const updatedPosition: number = audioObject.currentTime * 360 / audioObject.duration;
        setPosition(updatedPosition);
    }

    if (displayView === false) {
        return <View />;
    }

    const viewSize = audioPlayerSize;
    const playerIconSize = audioPlayerSize * 0.2;
    const playerIconContainerSize = audioPlayerSize * 0.6;

    const onSeekRequest = (amount: number) => {
        if ((amount !== Math.ceil(position)) && (amount !== Math.floor(position))) {
            togglePlayPause();
            let seekToValue: number = (amount / 360) * audioObject.duration;
            if (seekToValue < audioObject.duration) {
                //seek(seekToValue);
                audioObject.currentTime = seekToValue;
            }
            togglePlayPause();
        }
    };

    const renderCircularSlider = () => {
        if ((audioObject.duration < 30) || (position === -1)) {
            return <View />
        }
        return <View style={{ position: "absolute" }}>
            <CircularSlider
                width={viewSize}
                initialValue={0}
                label="savings"
                labelColor="#ffffff"
                knobColor={props.color}
                progressColorFrom={props.color}
                progressColorTo={props.color}
                progressSize={12}
                trackColor="#eeeeee"
                hideLabelValue={true}
                children={false}
                trackSize={16}
                min={0}
                max={360}
                dataIndex={position}
                onChange={onSeekRequest}
            />
        </View>
    }

    return (<View style={{ width: viewSize, height: viewSize, display: "flex", justifyContent: "center", alignItems: "center" }} >
        {renderCircularSlider()}
        <TouchableOpacity onPress={togglePlayPause} style={{ borderRadius: playerIconContainerSize / 2, width: playerIconContainerSize, height: playerIconContainerSize, backgroundColor: props.color, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <SVGView Component={isPlaying === true ? IcPause : IcPlay} width={playerIconSize} height={playerIconSize} color={appTheme.white} />
        </TouchableOpacity>
    </View>)
});

export default AudioPlayer;