import React, { useRef, useState } from "react";

import {
    Modal,
    SafeAreaView,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import SVGView from "../../../utils/SvgView";
import AppTheme from "../../../utils/Theme";
import { IcChevronDown, IcChevronLeft, IcChevronRight, IcClose } from "../../../utils/Icons";

type localizedString = {
    fr?: string
}

interface ActivityCellSummaryProps {
    title?: localizedString;
    body?: localizedString[];
    color: string;
    width: number;
    isEnable?: boolean;
}

const ActivityCellSummary = (props: ActivityCellSummaryProps) => {
    const { title, body, color, width, isEnable = false } = props;
    const [showBodies, setShowBodies] = useState<boolean>(false);
    const summaryViewRef = useRef<ScrollView>(null);

    const appTheme: AppTheme = new AppTheme();

    const toggleSummary = () => {
        if (isEnable === true) {
            setShowBodies(!showBodies);
        }
    }

    const summaryMoveTo = (pageIndex: number) => {
        summaryViewRef.current?.scrollTo({ x: pageIndex * appTheme.getBlockWidth(), y: 0, animated: true });
    }

    const getSummaryContent = () => {
        let summaryView = [];
        for (const aSummaryIndex in body) {
            if (Object.prototype.hasOwnProperty.call(body, aSummaryIndex)) {
                const aSummaryItem = body[parseInt(aSummaryIndex)];
                const leftButton = parseInt(aSummaryIndex) === 0 ? <View /> : <TouchableOpacity onPress={() => summaryMoveTo(parseInt(aSummaryIndex) - 1)}>
                    <SVGView Component={IcChevronLeft} size={appTheme.pixelPerfect(24)} color={color} />
                </TouchableOpacity>;
                const rightButton = parseInt(aSummaryIndex) === (body.length - 1) ? <View /> : <TouchableOpacity onPress={() => summaryMoveTo(parseInt(aSummaryIndex) + 1)}>
                    <SVGView Component={IcChevronRight} size={appTheme.pixelPerfect(24)} color={color} />
                </TouchableOpacity>;
                summaryView.push(
                    <View style={{ width: appTheme.getBlockWidth(), flex: 1, alignItems: "center", justifyContent: "center", paddingHorizontal: appTheme.pixelPerfect(10) }}>
                        <ScrollView style={{marginVertical:appTheme.pixelPerfect(40)}}>
                            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), paddingBottom: appTheme.pixelPerfect(10), color: appTheme.darkBlue, textAlign: "center" }}>{aSummaryItem}</Text>
                        </ScrollView>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", width: appTheme.getBlockWidth(), paddingHorizontal: appTheme.pixelPerfect(20), height: appTheme.pixelPerfect(60), alignItems: "center", position: "absolute", bottom:0 }}>
                            {leftButton}
                            {rightButton}
                        </View>
                    </View>)
            }
        }
        let summaryCompletView = (isEnable === true) ? <View style={{ marginBottom: appTheme.pixelPerfect(20), width: appTheme.getBlockWidth(), borderRadius: appTheme.pixelPerfect(20), borderWidth: 1, borderColor: color, flex: 1, alignItems: "center", backgroundColor: appTheme.white }}>
            <ScrollView
                ref={summaryViewRef}
                pagingEnabled={true}
                showsHorizontalScrollIndicator={false}
                horizontal={true}
                style={{ width: appTheme.getBlockWidth() }}>
                {summaryView}
            </ScrollView>
            <TouchableOpacity onPress={toggleSummary} style={{ backgroundColor: color, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(15), justifyContent: "center", alignItems: "center", position: "absolute", top: appTheme.pixelPerfect(10), right: appTheme.pixelPerfect(10) }}>
                <SVGView Component={IcClose} size={20} color={appTheme.white} />
            </TouchableOpacity>
        </View> : <View />;
        return <SafeAreaView style={{ width: appTheme.getFullAppWidth(), justifyContent: "center", alignItems: "center", minHeight: appTheme.getFullAppHeight() * 2 / 3 }}>
            {summaryCompletView}
        </SafeAreaView>
    }

    return <View style={{ width, alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(10), marginVertical: appTheme.pixelPerfect(10) }}>
        <TouchableOpacity onPress={toggleSummary} style={{ paddingHorizontal: appTheme.pixelPerfect(10), width: width - appTheme.pixelPerfect(10), height: appTheme.pixelPerfect(40), flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: appTheme.pixelPerfect(20), borderWidth: 1, borderColor: color, marginBottom: appTheme.pixelPerfect(10) }}>
            <Text style={{ flex: 1, fontFamily: appTheme.primaryBoldFont, textTransform: "uppercase", color, fontSize: appTheme.pixelPerfectForFont(10) }}>{title}</Text>
            <View style={{ marginStart: appTheme.pixelPerfect(10) }}>
                <SVGView Component={IcChevronDown} size={appTheme.pixelPerfect(24)} color={color} />
            </View>
        </TouchableOpacity>
        <Modal animationType="slide" visible={showBodies} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: color + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, justifyContent: "flex-end", backgroundColor: color + "80", width: appTheme.getFullAppWidth() }}>
                    {getSummaryContent()}
                </View>
            </View>
        </Modal>
    </View>
}

export default ActivityCellSummary;