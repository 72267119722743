import React, { useEffect, useRef, useState } from "react";

import { View, Image, Text, Platform, TouchableOpacity, Animated, ScrollView, FlatList } from "react-native";
import ComponentProps from "../../../../specific/utils/Interfaces/ComponentProps";
import AppTheme from "../../../utils/Theme";
import { useNavigate, useParams } from "react-router-dom";
import Activity, { getActivityAvalability } from "../../../models/Activity";
import ActivityIndicator from "../../../designSystem/ActivityIndicator/ActivityIndicator";
import ActivityMediaListStyle from "../../../styles/activities/ActivityMediaListStyle";
import { pop, push, showModal } from "../../../../specific/utils/Navigation/Navigation";
import SVGView from "../../../utils/SvgView";
import Style from "../../../utils/Style";
import { getLanguage } from "../../../utils/Localization/Localization";
import { ScreenActivityMediaSheet, ScreenSubscription } from "../../../utils/Constants";
import ActivityMediaSheet from "./ActivityMediaSheet";
import Images from "../../../../specific/utils/Images";
import User from "../../../data/user/User";
import PremiumButton from "../../../designSystem/PremiumButton";
import { propertiesEventCategory, trackEvent } from "../../../services/Tracking/Analytics";
import { IcBack, IcPlay } from "../../../utils/Icons";

interface ActivityMediaListProps extends ComponentProps {
    category: any,
    activity: Activity,
    webCloseRequest?: () => void
}

const ActivityMediaList = (props: ActivityMediaListProps) => {

    const appTheme: AppTheme = new AppTheme();
    const activityMediaListStyle: ActivityMediaListStyle = new ActivityMediaListStyle();
    const images: Images = new Images();
    const user: User = User.getInstance();

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [mediaSelected, setMediaSelected] = useState<any>(undefined);
    const [refresh, setRefresh] = useState<number>(0);
    const viewOpacity: Animated.Value = useRef(new Animated.Value(0)).current;

    const scrollViewRef = useRef<ScrollView>(null);

    let propsData = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const { category, activity, componentId, webCloseRequest } = propsData;

    const onViewDidAppear = () => {
        setViewDidAppear(true);
    }

    useEffect(() => {
        if (viewDidAppear === true) {
            trackEvent("activity_viewed", {
                [propertiesEventCategory]: category.analytics_key,
                activity_name: activity?.analytics_key
            })
            Animated.timing(viewOpacity, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: false
            }).start();
        }
    }, [viewDidAppear]);

    const goBack = () => {
        if (Platform.OS === "web") {
            if (mediaSelected === undefined) {
                if (webCloseRequest !== undefined) {
                    webCloseRequest();
                }
                Animated.timing(viewOpacity, {
                    toValue: 0,
                    duration: 1000,
                    useNativeDriver: false
                }).start(() => {

                });
            } else {
                setMediaSelected(undefined);
            }
        } else {
            pop({ componentId, navigate });
        }
    }

    const getBackButton = () => {
        // Si on est en mobile, on a un bouton retour
        // En web un bouton nous ramenant au menu
        const buttonSize = appTheme.pixelPerfect(30);
        const leftPosition = Platform.OS === "web" ? appTheme.pixelPerfect(20) : appTheme.pixelPerfect(10);
        const topPosition = Platform.OS === "web" ? appTheme.pixelPerfect(15) : appTheme.pixelPerfect(5) + appTheme.topInsets;
        let leftButtonContent = <SVGView Component={IcBack} color="#1C3656" size={appTheme.pixelPerfect(12)} />;
        return <View style={{ position: "absolute", top: topPosition, left: leftPosition }}>
            <TouchableOpacity onPress={goBack} style={[Style.shadowed, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: appTheme.white, justifyContent: "center", alignItems: "center" }]}>
                {leftButtonContent}
            </TouchableOpacity>
        </View>
    }

    useEffect(() => {
        const step = mediaSelected === undefined ? 0 : 1;
        const widthToScroll = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
        scrollViewRef.current?.scrollTo({ x: widthToScroll * step, y: 0, animated: true });
        if (Platform.OS === "web") {
            window.scrollTo(0, 0);
        }
    }, [mediaSelected]);

    const onMediaCompleted = () => {
        setRefresh(new Date().getTime());
    }

    const showMedia = (media: any) => {
        if (getActivityAvalability(activity) === false) {
            showModal({
                componentId,
                navigate,
                name: ScreenSubscription,
                passProps: {
                    isModal: true
                }
            })
        } else {
            if (Platform.OS === "web") {
                if (media !== undefined) {
                    trackEvent("media_viewed", {
                        activity_name: activity?.analytics_key,
                        media_name: media.id
                    });
                }
                setMediaSelected(media);
            } else {
                push({
                    componentId,
                    navigate,
                    name: ScreenActivityMediaSheet,
                    passProps: {
                        activity,
                        category,
                        media,
                        onMediaCompleted
                    }
                })
            }
        }
    }

    const mobileMediaCell = ({ item, index }) => {
        const { media } = activity;
        const isFirst = parseInt(index) === 0;
        const isLast = parseInt(index) + 1 === media.length;
        return <View style={{ alignItems: "center" }}>
            {mediaCell({ media: item, isFirst, isLast })}
        </View>
    }

    const mediaCell = (data: { media: any, isFirst: boolean, isLast: boolean }) => {
        const { media, isFirst, isLast } = data;
        const { clr, key } = category;
        const { locked = true } = media;
        let isMediaInUserHistory = locked === false ? true : false;
        if (locked === true) {
            // Si le media est "locké" par défaut on doit vérifier s'il a déjà été lu par l'utilisateur
            if (user.media !== undefined) {
                if (user.media[key] !== undefined) {
                    if (user.media[key].indexOf(media.id) !== -1) {
                        isMediaInUserHistory = true;
                    }
                }
            }
        }


        const playButtonSize = appTheme.pixelPerfect(36);
        const bottomLine = isLast === true ? <View /> : <View style={{ position: "absolute", width: 1, height: appTheme.pixelPerfect(35), bottom: 0, left: playButtonSize / 2, backgroundColor: clr.btn }} />;
        const topLine = isFirst === true ? <View /> : <View style={{ position: "absolute", width: 1, height: appTheme.pixelPerfect(35), top: 0, left: playButtonSize / 2, backgroundColor: clr.btn }} />;
        const actionButtonColor = isMediaInUserHistory === true ? appTheme.white : clr.btn;
        const actionButtonBackgroundColor = isMediaInUserHistory === true ? clr.btn : appTheme.white;
        const mediaTitleOpacity = isMediaInUserHistory === true ? 1 : 0.5;
        const playButton = getActivityAvalability(activity) === true ? <SVGView size={appTheme.pixelPerfect(14)} Component={IcPlay} color={actionButtonColor} /> : <PremiumButton size={playButtonSize} iconSize={appTheme.pixelPerfect(14)} componentId={componentId} />;
        const mediaCellWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
        let authorView = <View />;
        if (media.author !== undefined) {
            authorView = <Text style={{ color: appTheme.darkBlue, opacity: mediaTitleOpacity, fontFamily: appTheme.primarySemiBoldFont, fontSize: Platform.OS === "web" ? appTheme.pixelPerfectForFont(10) : appTheme.pixelPerfectForFont(8) }}>{media.author}</Text>
        }
        return <TouchableOpacity onPress={() => showMedia(media)} style={{ flexDirection: "row", height: appTheme.pixelPerfect(70), justifyContent: "flex-start", alignItems: "center", width: appTheme.getFlexDirectionForSplitScreen() === "row" ? mediaCellWidth * 0.6 : mediaCellWidth * 0.8 }}>
            {topLine}
            {bottomLine}
            <View style={{ borderWidth: 1, borderColor: clr.btn, width: playButtonSize, height: playButtonSize, borderRadius: playButtonSize / 2, justifyContent: "center", alignItems: "center", backgroundColor: actionButtonBackgroundColor, marginEnd: appTheme.pixelPerfect(15) }}>
                {playButton}
            </View>
            <View style={{flex:1, paddingRight:appTheme.pixelPerfect(10), alignItems:"flex-start", justifyContent:"center"}}>
                <Text numberOfLines={3} style={{ color: appTheme.darkBlue, opacity: mediaTitleOpacity, fontFamily: appTheme.primaryBoldFont, fontSize: Platform.OS === "web" ? appTheme.pixelPerfectForFont(10) : appTheme.pixelPerfectForFont(8) }}>{media.title[getLanguage()]}</Text>
                {authorView}
            </View>
        </TouchableOpacity>
    }

    const getMediaIntro = () => {
        let title: string = activity.title[getLanguage()];
        let body: string = activity.body[getLanguage()];
        if (activity[user.profile] !== undefined) {
            const userProfileItem = activity[user.profile];
            if ((userProfileItem.title !== undefined) && (userProfileItem.title[getLanguage()] !== undefined)) {
                title = userProfileItem.title[getLanguage()];
            }
            if ((userProfileItem.body !== undefined) && (userProfileItem.body[getLanguage()] !== undefined)) {
                body = userProfileItem.body[getLanguage()];
            }
        }
        return <View style={{ width: appTheme.getBlockWidth(), alignItems: "center" }}>
            <Text style={{ paddingHorizontal: Platform.OS === "web" ? appTheme.pixelPerfect(30) : appTheme.pixelPerfect(10), textTransform: "uppercase", marginVertical: appTheme.pixelPerfect(15), color: appTheme.darkBlue, fontFamily: appTheme.primaryBoldFont, fontSize: Platform.OS === "web" ? appTheme.pixelPerfectForFont(12) : appTheme.pixelPerfectForFont(10) }}>{title}</Text>
            <Text style={{ paddingHorizontal: Platform.OS === "web" ? appTheme.pixelPerfect(30) : appTheme.pixelPerfect(10), textAlign: "center", fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: Platform.OS === "web" ? appTheme.pixelPerfectForFont(11) : appTheme.pixelPerfectForFont(9) }}>{body}</Text>
        </View>
    }

    const getMediaList = () => {
        let { media } = activity;
        if ((activity[user.profile] !== undefined) && (activity[user.profile].media !== undefined)) {
            media = activity[user.profile].media;
        }
        if ((media !== undefined) && (media.length > 0)) {
            let mediaListView = [];
            for (const aMediaIndex in media) {
                if (Object.prototype.hasOwnProperty.call(media, aMediaIndex)) {
                    const aMedia = media[aMediaIndex];
                    const isFirst = parseInt(aMediaIndex) === 0;
                    const isLast = parseInt(aMediaIndex) + 1 === media.length;
                    mediaListView.push(mediaCell({ media: aMedia, isFirst, isLast }));
                }
            }
            if (Platform.OS === "web") {
                return <View style={{ width: appTheme.getBlockWidth(), alignItems: "center" }}>
                    {getMediaIntro()}
                    <View style={{ marginTop: appTheme.pixelPerfect(20), width: appTheme.getBlockWidth(), alignItems: "center" }}>
                        {mediaListView}
                    </View>
                </View>
            }
            return <FlatList
                extraData={refresh}
                ListHeaderComponent={getMediaIntro}
                data={media}
                style={{ width: appTheme.getFullAppWidth() }}
                renderItem={mobileMediaCell}
                keyExtractor={(index, item) => item.key} />
        }
        return <View />;
    }

    const getMediaSheet = () => {
        if (Platform.OS === "web") {
            return <ActivityMediaSheet activity={activity} category={category} media={mediaSelected} />;
        }
        return <View />;
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator loading={true} color={appTheme.darkBlue} />
            </View>
        }
        let img_url: string = activity.img_url;
        let app_image: string = activity.app_image;
        const { clr } = category;
        // On va regarder si on a une iimage spécifique pour le type d'utilisateur
        if (activity[user.profile] !== undefined) {
            const userProfileItem = activity[user.profile];
            if (userProfileItem.app_image !== undefined) {
                app_image = userProfileItem.app_image;
            }
            if (userProfileItem.img_url !== undefined) {
                img_url = userProfileItem.img_url;
            }
        }
        const imageSource = images[app_image] !== undefined ? images[app_image] : { url: img_url };
        return <Animated.View style={{ marginBottom: appTheme.pixelPerfect(30), width: appTheme.getFullScreenWidth(), alignItems: "center", opacity: Platform.OS === "web" ? viewOpacity : 1 }}>
            <View style={activityMediaListStyle.innerContainerStyle}>
                <Image source={imageSource} style={activityMediaListStyle.headerImage} resizeMode="cover" />
                <ScrollView scrollEnabled={false} ref={scrollViewRef} showsHorizontalScrollIndicator={false} pagingEnabled={true} horizontal={true} style={Platform.OS === "web" ? activityMediaListStyle.horizontalScrollView : activityMediaListStyle.horizontalScrollViewMobile}>
                    <View style={activityMediaListStyle.mediaContent}>
                        {getMediaList()}
                    </View>
                    <View style={activityMediaListStyle.mediaContent}>
                        {getMediaSheet()}
                    </View>
                </ScrollView>
                {getBackButton()}
            </View>
        </Animated.View>
    }

    return getContent()
}

export default ActivityMediaList;