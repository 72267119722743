import React, { useEffect, useRef, useState } from "react";

import {
    View,
    Text,
    TextStyle,
    TouchableOpacity,
    Linking,
    Dimensions,
    FlatList,
    Modal,
    Image
} from "react-native";
import AppTheme from "../../utils/Theme";
import TextInput from "../../designSystem/TextInput/RoundCornerTextInput";
import Divider from "../../designSystem/Divider/Divider";
import Button from "../../designSystem/Button";
import SVGView from "../../utils/SvgView";
import { default as IcPlus } from "../../assets/svg/icons/solid/plus.svg";
import { default as IcMinus } from "../../assets/svg/icons/solid/minus.svg";
import { default as IcTrash } from "../../assets/svg/icons/regular/trash.svg";
import RadioButton from "../../designSystem/RadioButton";
import { OfferType, UserCategory, UserType } from "../../utils/Constants";
import { createPremiumCardForCustomers, getUserWithEmail } from "../../../specific/services/Specific_Database";

import Alert from "../Notification/Alert";

import Select from 'react-select';
import { subCaracteristics } from "../Admin/AdminGiftTreatment";
import User from "../../data/user/User";
import { getSessionsActivities, getSessionsPremiumCards } from "../../../specific/utils/LocalStorage";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { addDataToUser } from "../../services/Database";

import Papa from 'papaparse';
import { menuWidth } from "./LHQHome";

type ImportRequest = {
    Abo: string,
    Batch: string,
    "Commentaires (optionnel)": string,
    Demandeur: string,
    "Destinataire (Prénom Nom)": string,
    "E-mail": string,
    "Type": string,
    "Offre": string
}

interface LHQCreateCodeProps {
    goBack?: () => void
}

export type PremiumCard = {
    code: string,
    comment: string,
    creation_date: number,
    customer_email: string,
    customer_name: string,
    duration: number,
    last_update: number,
    origin: string,
    product_id: string,
    requested_by: string,
    saved: boolean,
    status: string,
    sub_type: string,
    type: string,
    offer: string
}

type SubType =
    "LAUREAT_LAE1" |
    "LAUREAT_LAE3" |
    "LAUREAT_LAE12" |
    "LAUREAT_LAM1" |
    "LAUREAT_LAM3" |
    "LAUREAT_LAM12" |
    "PROF_COMM" |
    "PROF_RS" |
    "PROF_AMB" |
    "PARENTALITE_RS" |
    "PARENTALITE_AMB" |
    "JOURNALISTE_LAM" |
    "JOURNALISTE_LAE" |
    "JOURNALISTE_AMB" |
    "INSTITUTIONNEL" |
    "INSTITUTIONNEL_AMB" |
    "PARTENAIRE" |
    "PARTENAIRE_AMB" |
    "LILI_FAMILY" |
    "LILI_TEAM" |
    "PETITCHAMPION_LAE2" |
    "CUSTOM";

const LHQCreateCode = (props: LHQCreateCodeProps) => {

    const appTheme: AppTheme = new AppTheme;
    const user: User = User.getInstance();

    const [fileData, setFileData] = useState<ImportRequest | undefined>(undefined);
    const [email, setEmail] = useState<string>("");
    const [duration, setDuration] = useState<number>(1);
    const [numberOfCodes, setNumberOfCodes] = useState<number>(1);
    const [codeType, setCodeType] = useState<UserType>("teacher");
    const [userCategory, setUserCategory] = useState<UserCategory>("final_user");
    const [codes, setCodes] = useState<{ [key: string]: PremiumCard }>({});
    const [comment, setComment] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [offerType, setOfferType] = useState<OfferType>("gift");
    const [subType, setSubType] = useState<SubType | undefined>(undefined);
    const [premiumCards, setPremiumCards] = useState<any>(undefined);

    const alertRef = useRef<Alert>(null);

    const { goBack } = props;

    let formWidth: number = (Dimensions.get("window").width - menuWidth - appTheme.pixelPerfect(60)) / 3;
    if (formWidth > appTheme.pixelPerfect(320)) {
        formWidth = appTheme.pixelPerfect(320);
    }
    let listWidth: number = Dimensions.get("window").width - menuWidth - formWidth - appTheme.pixelPerfect(110);
    if (listWidth > appTheme.pixelPerfect(640)) {
        listWidth = appTheme.pixelPerfect(640);
    }

    const h1Style: TextStyle = {
        fontFamily: appTheme.primaryBoldFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(18)
    }

    const h2Style: TextStyle = {
        fontFamily: appTheme.primaryMediumFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(16)
    }

    const h3Style: TextStyle = {
        fontFamily: appTheme.primaryMediumFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(14)
    }

    const labelStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(10),
        textAlign: "left"
    }

    const pStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.textColor,
        fontSize: appTheme.pixelPerfectForFont(14)
    }

    const aStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.textColor,
        fontSize: appTheme.pixelPerfectForFont(14),
        textDecorationLine: "underline"
    }

    const openLink = (link: string) => {
        Linking.openURL(link);
    }

    type DurationDefinition = {
        duration: number,
        name: string
    }

    type UserTypeDefinition = {
        type: UserType,
        name: string
    }

    type UserCategoryDefinition = {
        category: UserCategory,
        name: string
    }

    type OfferTypeDefinition = {
        category: OfferType,
        name: string
    }

    const getPremiumCards = async () => {
        const sessionPremiumCards = await getSessionsPremiumCards();
        setPremiumCards(sessionPremiumCards);
    }

    useEffect(() => {
        if (subType !== undefined) {
            let aSubType = subCaracteristics[subType];
            setDuration(aSubType.duration_in_monts);
            if (aSubType.las === true) {
                setCodeType("teacher");
            } else if (aSubType.lae === true) {
                setCodeType("family");
            } else if (aSubType.lep === true) {
                setCodeType("animator");
            }
        }
    }, [subType]);

    useEffect(() => {
    }, [codeType]);

    const getSubTypeSelection = () => {
        let subTypeOptions: { value: string, label: string }[] = [];
        for (const aSubTypeKey in subCaracteristics) {
            if (Object.prototype.hasOwnProperty.call(subCaracteristics, aSubTypeKey)) {
                const element = subCaracteristics[aSubTypeKey];
                subTypeOptions.push({
                    value: aSubTypeKey,
                    label: aSubTypeKey
                })
            }
        }
        return <Select
            onChange={(e) => setSubType(e.value)}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: appTheme.pixelPerfect(30),
                    borderRadius: appTheme.pixelPerfect(10),
                    fontFamily: appTheme.primaryFont
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                    ...styles,
                    fontFamily: appTheme.primaryFont
                })
            }} options={subTypeOptions} />
    }

    const changeDuration = (addAMonth: boolean) => {
        if (addAMonth === true) {
            setDuration(duration + 1);
        } else {
            if (duration > 1) {
                setDuration(duration - 1);
            }
        }
    }

    const getDurationChoiceSelection = () => {
        return <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity onPress={() => changeDuration(false)} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), backgroundColor: "lightgrey", borderTopLeftRadius: appTheme.pixelPerfect(5), borderBottomLeftRadius: appTheme.pixelPerfect(5), marginHorizontal: appTheme.pixelPerfect(5), justifyContent: "center", alignItems: "center" }}>
                <SVGView Component={IcMinus} size={appTheme.pixelPerfect(12)} color={appTheme.white} />
            </TouchableOpacity>
            <Text style={{ width: appTheme.pixelPerfect(30), fontSize: appTheme.pixelPerfectForFont(12), textAlign: "center", color: appTheme.darkBlue }}>{duration.toString()}</Text>
            <TouchableOpacity onPress={() => changeDuration(true)} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), backgroundColor: "lightgrey", borderTopRightRadius: appTheme.pixelPerfect(5), borderBottomRightRadius: appTheme.pixelPerfect(5), marginHorizontal: appTheme.pixelPerfect(5), justifyContent: "center", alignItems: "center" }}>
                <SVGView Component={IcPlus} size={appTheme.pixelPerfect(12)} color={appTheme.white} />
            </TouchableOpacity>
        </View>
    }

    const getOfferCategorySelection = () => {
        let offerTypeSelection = [];
        const offerTypeArray: OfferTypeDefinition[] = [
            {
                category: "gift",
                name: "Cadeau"
            },
            {
                category: "sale",
                name: "Vente"
            }
        ];
        for (const aUserTypeIndex in offerTypeArray) {
            if (Object.prototype.hasOwnProperty.call(offerTypeArray, aUserTypeIndex)) {
                const anOfferTypeDefinition: OfferTypeDefinition = offerTypeArray[aUserTypeIndex];
                if (offerTypeSelection.length > 0) {
                    offerTypeSelection.push(<Divider size={5} />);
                }
                let isButtonEnable: boolean = true;
                let buttonOpacity: number = 1;
                if (anOfferTypeDefinition.category !== offerType) {
                    if ((codes !== undefined) && (Object.keys(codes).length > 0)) {
                        isButtonEnable = false;
                        buttonOpacity = 0.4;
                    }
                }
                offerTypeSelection.push(<RadioButton disabled={!isButtonEnable} value={anOfferTypeDefinition.category === offerType} style={{ width: formWidth, opacity: buttonOpacity }} centerButton={true} onPress={() => setOfferType(anOfferTypeDefinition.category)}>
                    <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(9) }}>{anOfferTypeDefinition.name}</Text>
                </RadioButton>);
            }
        }
        return offerTypeSelection;
    }

    const getUserCategorySelection = () => {
        let userCategorySelection = [];
        const userCategoryArray: UserCategoryDefinition[] = [
            {
                category: "final_user",
                name: "Utilisateur final"
            },
            {
                category: "manager",
                name: "Gestionnaire"
            }
        ];
        for (const aUserTypeIndex in userCategoryArray) {
            if (Object.prototype.hasOwnProperty.call(userCategoryArray, aUserTypeIndex)) {
                const aUserCategoryDefinition: UserCategoryDefinition = userCategoryArray[aUserTypeIndex];
                if (userCategorySelection.length > 0) {
                    userCategorySelection.push(<Divider size={5} />);
                }
                let isButtonEnable: boolean = true;
                let buttonOpacity: number = 1;
                if (aUserCategoryDefinition.category !== userCategory) {
                    if ((codes !== undefined) && (Object.keys(codes).length > 0)) {
                        isButtonEnable = false;
                        buttonOpacity = 0.4;
                    }
                }
                userCategorySelection.push(<RadioButton disabled={!isButtonEnable} value={aUserCategoryDefinition.category === userCategory} style={{ width: formWidth, opacity: buttonOpacity }} centerButton={true} onPress={() => setUserCategory(aUserCategoryDefinition.category)}>
                    <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(9) }}>{aUserCategoryDefinition.name}</Text>
                </RadioButton>);
            }
        }
        return userCategorySelection;
    }

    const getUserTypeSelection = () => {
        let userTypeSelection = [];
        const userTypeArray: UserTypeDefinition[] = [
            {
                type: "teacher",
                name: "Enseignant"
            },
            {
                type: "animator",
                name: "Animateur"
            },
            {
                type: "family",
                name: "Parent"
            }
        ];
        for (const aUserTypeIndex in userTypeArray) {
            if (Object.prototype.hasOwnProperty.call(userTypeArray, aUserTypeIndex)) {
                const aUserTypeDefinition: UserTypeDefinition = userTypeArray[aUserTypeIndex];
                if (userTypeSelection.length > 0) {
                    userTypeSelection.push(<Divider size={5} />);
                }
                userTypeSelection.push(<RadioButton value={aUserTypeDefinition.type === codeType} style={{ width: formWidth }} centerButton={true} onPress={() => setCodeType(aUserTypeDefinition.type)}>
                    <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(9) }}>{aUserTypeDefinition.name}</Text>
                </RadioButton>);
            }
        }
        return userTypeSelection;
    }

    const changeCodeNumber = (forward: boolean) => {
        if (forward === true) {
            setNumberOfCodes(numberOfCodes => numberOfCodes + 1);
        } else {
            setNumberOfCodes(numberOfCodes => numberOfCodes - 1);
        }
    }

    const deleteCode = (item: PremiumCard) => {
        let updatedCodes: { [key: string]: PremiumCard } = {};
        for (const aCodeKey in codes) {
            if (Object.prototype.hasOwnProperty.call(codes, aCodeKey)) {
                const aCode: PremiumCard = codes[aCodeKey];
                if (aCode.code !== item.code) {
                    updatedCodes[aCode.code] = aCode;
                }
            }
        }
        setCodes(updatedCodes);
    }

    const codeCell = ({ item, index }) => {
        /*
        <QRCodeStyled
                data={item.code}
                style={{ backgroundColor: 'white' }}
                padding={20}
                pieceSize={3} />
                */
        const aCode: PremiumCard = codes[item];
        const saved: string = aCode.saved === false ? "À envoyer ❌" : "Envoyé ! 👍🏻";
        const duratonText: string = aCode.duration === 12 ? "1 an" : aCode.duration + " mois";
        return <View style={{ padding: 10, flexDirection: "row", borderBottomWidth: 1, borderColor: appTheme.talk + "40", alignItems: "flex-start", justifyContent: "space-between" }}>
            <View style={{ flex: 1 }}>
                <Text style={h2Style}>{aCode.code}</Text>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flex: 1, alignItems: "flex-start" }}>
                        <Text style={labelStyle}>{"Nom : " + aCode.customer_name}</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: "flex-start" }}>
                        <Text style={labelStyle}>{"E-mail : " + aCode.customer_email}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flex: 1, alignItems: "flex-start" }}>
                        <Text style={labelStyle}>{"Durée : " + duratonText}</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: "flex-start" }}>
                        <Text style={labelStyle}>{"Type : " + aCode.type}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flex: 1, alignItems: "flex-start" }}>
                        <Text style={labelStyle}>{"Forfait : " + aCode.sub_type}</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: "flex-start" }}>
                        <Text style={labelStyle}>{"Offre : " + aCode.offer}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flex: 1, alignItems: "flex-start" }}>
                        <Text style={labelStyle}>{"Commentaire : " + aCode.comment}</Text>
                    </View>
                </View>
                <View style={{ alignItems: "flex-end", marginTop: appTheme.pixelPerfect(10) }}>
                    <Text style={pStyle}>{saved}</Text>
                </View>
            </View>
            <TouchableOpacity onPress={() => deleteCode(aCode)} style={{ paddingHorizontal: 10 }}>
                <SVGView Component={IcTrash} size={appTheme.pixelPerfect(20)} color={appTheme.darkBlue} />
            </TouchableOpacity>
        </View>
    }

    const createCode = (length: number) => {
        let result: string = "";
        const characters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const charactersLength: number = characters.length;
        let counter: number = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const generateAnUniqueCode = (existingCodes: string[]) => {
        let aCode: string = createCode(6);
        const premiumCardsCodes: string[] = Object.keys(premiumCards.data);
        while ((premiumCardsCodes.indexOf(aCode) !== -1) || (existingCodes.indexOf(aCode) !== -1)) {
            aCode = createCode(6);
        }
        return aCode;
    }

    const createCodes = async () => {
        if ((email.length === 0) || (name.length === 0)) {
            let title = "Tu as oublié l'e-mail ou le nom du destinataire !";
            let body = "🤦🏻";
            alertRef.current?.showAlert({
                title,
                body
            });
        } else {
            let nbOfCard = 0;
            let updatedCodes: { [key: string]: PremiumCard } = { ...codes };
            while (nbOfCard < numberOfCodes) {
                nbOfCard++;
                // Génération d'un code
                let code: string = generateAnUniqueCode(Object.keys(updatedCodes));
                //code = "AXEL";
                let product_id: string = "cool.lili.subnr.laspremiumthree";
                if (codeType === "teacher") {
                    product_id = duration === 12 ? "cool.lili.subnr.laspremiumtwelve" : duration === 1 ? "cool.lili.subnr.laspremiumone" : "cool.lili.subnr.laspremiumthree";
                } else if (codeType === "family") {
                    product_id = duration === 12 ? "cool.lili.subnr.lahpremiumtwelve" : duration === 1 ? "cool.lili.subnr.lahpremiumone" : "cool.lili.subnr.lahpremiumthree";
                } else if (codeType === "animator") {
                    product_id = duration === 12 ? "cool.lili.subnr.laapremiumtwelve" : duration === 1 ? "cool.lili.subnr.laapremiumone" : "cool.lili.subnr.laapremiumthree";
                }
                const subscriptionData: PremiumCard = {
                    product_id,
                    origin: 'HQ',
                    code,
                    status: 'toBeRedeemed',
                    creation_date: new Date().getTime(),
                    last_update: new Date().getTime(),
                    customer_email: email.trim().toLowerCase(),
                    duration,
                    sub_type: subType ?? "CUSTOM",
                    saved: false,
                    customer_name: name,
                    comment,
                    type: codeType,
                    offer: offerType,
                    requested_by: user.email
                };
                updatedCodes[code] = subscriptionData;
            }
            setCodes(updatedCodes);
        }
    }


    const checkManagerAccount = async () => {
        try {
            const userAccount = await getUserWithEmail({ user_email: email });
            if ((userAccount !== undefined) && (userAccount.length > 0)) {
                // On a un compte
                let userAccountData: User = userAccount[0].data;
                if ((userAccountData.manager === undefined) || ((userAccountData.mmanager !== undefined) && (userAccountData.mmanager === false))) {
                    await addDataToUser({ key: "manager", value: true, user_id: userAccount[0].key });
                    let title = "L'utilisateur " + email + " a été upgradé en tant que Manager!";
                    let body = "🥳";
                    alertRef.current?.showAlert({
                        title,
                        body
                    });
                }
            } else {
                let title = "L'utilisateur " + email + " n'a pas de compte!";
                let body = "Pas de soucis. Dès qu'il aura créé un compte tu pourras le passer en manager dans la gestion des utilisateurs.";
                alertRef.current?.showAlert({
                    title,
                    body
                });
            }
        } catch (error) {
        }
    }

    const recordCodesOnDB = async () => {
        let updatedCodes: { [key: string]: PremiumCard } = { ...codes };
        for (const aCodeKey in codes) {
            if (Object.prototype.hasOwnProperty.call(codes, aCodeKey)) {
                const aCode = codes[aCodeKey];
                await createPremiumCardForCustomers(aCode);
                aCode.saved = true;
                updatedCodes[aCodeKey] = aCode;
            }
        }
        setCodes(updatedCodes);
    }

    // Import d'un fichier
    useEffect(() => {
        if (fileData !== undefined) {
            // On va transformer les données en données de carte
            let updatedCodes: { [key: string]: PremiumCard } = { ...codes };
            for (const aFileRequestIndex in fileData) {
                if (Object.prototype.hasOwnProperty.call(fileData, aFileRequestIndex)) {
                    const aFileRequest: ImportRequest = fileData[aFileRequestIndex];
                    const subCaracteristicsForId = subCaracteristics[aFileRequest.Type];
                    // Génération d'un code
                    let code: string = generateAnUniqueCode(Object.keys(updatedCodes));
                    //code = "AXEL";
                    let importCodetype: string = "family";
                    let product_id: string = "cool.lili.subnr.laspremiumthree";
                    if (subCaracteristicsForId.las === true) {
                        importCodetype = "teacher";
                        product_id = subCaracteristicsForId.duration_in_monts === 12 ? "cool.lili.subnr.laspremiumtwelve" : subCaracteristicsForId.duration_in_monts === 1 ? "cool.lili.subnr.laspremiumone" : "cool.lili.subnr.laspremiumthree";
                    } else if (subCaracteristicsForId.lae === true) {
                        product_id = subCaracteristicsForId.duration_in_monts === 12 ? "cool.lili.subnr.lahpremiumtwelve" : subCaracteristicsForId.duration_in_monts === 1 ? "cool.lili.subnr.lahpremiumone" : "cool.lili.subnr.lahpremiumthree";
                    } else if (subCaracteristicsForId.lep === true) {
                        importCodetype = "animator";
                        product_id = subCaracteristicsForId.duration_in_monts === 12 ? "cool.lili.subnr.laapremiumtwelve" : subCaracteristicsForId.duration_in_monts === 1 ? "cool.lili.subnr.laapremiumone" : "cool.lili.subnr.laapremiumthree";
                    }
                    const subscriptionData: PremiumCard = {
                        product_id,
                        origin: 'HQ',
                        code,
                        status: 'toBeRedeemed',
                        creation_date: new Date().getTime(),
                        last_update: new Date().getTime(),
                        customer_email: aFileRequest["E-mail"].trim().toLowerCase(),
                        duration: subCaracteristicsForId.duration_in_monts,
                        sub_type: aFileRequest.Type,
                        saved: false,
                        customer_name: aFileRequest["Destinataire (Prénom Nom)"],
                        comment: aFileRequest["Commentaires (optionnel)"],
                        type: importCodetype,
                        offer: aFileRequest["Offre"] ?? "unknown",
                        requested_by: aFileRequest.Demandeur
                    };
                    updatedCodes[code] = subscriptionData;
                }
            }
            setCodes(updatedCodes);
        }
    }, [fileData]);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            alert('Veuillez sélectionner un fichier.');
            return;
        }

        if (file.type !== 'text/csv') {
            alert('Veuillez sélectionner un fichier CSV.');
            return;
        }

        Papa.parse(file, {
            header: true, // Indique que la première ligne du fichier contient les en-têtes
            skipEmptyLines: true, // Ignore les lignes vides
            complete: (result) => {
                console.log({ result });
                setFileData(result.data); // Stocke les données JSON dans l'état
            },
            error: (error) => {
                console.error('Erreur lors de la lecture du fichier CSV:', error);
            },
        });
    }

    const getContent = () => {
        if (premiumCards === undefined) {
            return <View onLayout={getPremiumCards} style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator loading={true} color={appTheme.grow} />
            </View>
        }
        let userDataEnable: boolean = true;
        let userDataOpacity: number = 1;
        if ((userCategory === "manager") && (codes !== undefined) && (Object.keys(codes).length > 0)) {
            userDataEnable = false;
            userDataOpacity = 0.5;
        }
        return <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={h1Style}>
                    Création de cartes PREMIUM
                </Text>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <Button style={{ width: appTheme.pixelPerfect(200) }} disabled={Object.keys(codes).length === 0} title={"Envoyer les codes"} onPress={recordCodesOnDB} />
                </View>
            </View>
            <Divider />
            <View style={{ flexDirection: "row" }}>
                <View style={{ width: formWidth + appTheme.pixelPerfect(30), paddingEnd: appTheme.pixelPerfect(30), borderEndWidth: 1, borderColor: "#DEDEDE" }}>
                    <View style={{ width: formWidth }}>
                        <Text style={h2Style}>
                            Tu peux importer une fichier CSV
                        </Text>
                        <Divider />
                        <input
                            type="file"
                            accept=".csv"
                            onChange={handleFileUpload}
                            style={{ marginBottom: '20px' }}
                        />
                    </View>
                    <Divider />
                    <View style={{ width: formWidth }}>
                        <Text style={labelStyle}>
                            OU
                        </Text>
                    </View>
                    <Divider />
                    <View style={{ width: formWidth }}>
                        <Text style={h2Style}>
                            Remplir le formulaire ci-dessous
                        </Text>
                        <Text style={labelStyle}>
                            E-mail du destinataire *
                        </Text>
                        <Divider size={5} />
                        <TextInput style={{ opacity: userDataOpacity }} editable={userDataEnable} value={email} onChangeText={(text) => setEmail(text)} placeholder={"Adresse e-mail du destinataire"} />
                        <Text style={labelStyle}>
                            Nom du destinataire *
                        </Text>
                        <Divider size={5} />
                        <TextInput style={{ opacity: userDataOpacity }} editable={userDataEnable} value={name} onChangeText={(text) => setName(text)} placeholder={"Prénom et nom du destinataire"} />
                        <Text style={labelStyle}>
                            Commentaires
                        </Text>
                        <Divider size={5} />
                        <TextInput editable={userDataEnable} style={{ justifyContent: "flex-start", opacity: userDataOpacity }} value={comment} onChangeText={(text) => setComment(text)} placeholder={"Commentaires..."} />
                        <Divider />
                        <Text style={labelStyle}>
                            Catégorie du destinataire
                        </Text>
                        <Divider size={5} />
                        {getUserCategorySelection()}
                        <Divider />
                        <Text style={labelStyle}>
                            Catégorie de carte PREMIUM
                        </Text>
                        <Divider size={5} />
                        {getSubTypeSelection()}
                        <Divider />
                        <Text style={labelStyle}>
                            Type d'offre
                        </Text>
                        <Divider size={5} />
                        {getOfferCategorySelection()}
                        <Divider />
                        <Text style={labelStyle}>
                            Catégorie de compte
                        </Text>
                        <Divider size={5} />
                        {getUserTypeSelection()}
                        <Divider />
                        <Text style={labelStyle}>
                            Nombre de mois
                        </Text>
                        <Divider size={5} />
                        {getDurationChoiceSelection()}
                        <Text style={labelStyle}>
                            Nombre de cartes PREMIUM
                        </Text>
                        <Divider size={5} />
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <TouchableOpacity onPress={() => changeCodeNumber(false)} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), backgroundColor: "lightgrey", borderTopLeftRadius: appTheme.pixelPerfect(5), borderBottomLeftRadius: appTheme.pixelPerfect(5), marginHorizontal: appTheme.pixelPerfect(5), justifyContent: "center", alignItems: "center" }}>
                                <SVGView Component={IcMinus} size={appTheme.pixelPerfect(12)} color={appTheme.white} />
                            </TouchableOpacity>
                            <Text style={{ width: appTheme.pixelPerfect(30), fontSize: appTheme.pixelPerfectForFont(12), textAlign: "center", color: appTheme.darkBlue }}>{numberOfCodes.toString()}</Text>
                            <TouchableOpacity onPress={() => changeCodeNumber(true)} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), backgroundColor: "lightgrey", borderTopRightRadius: appTheme.pixelPerfect(5), borderBottomRightRadius: appTheme.pixelPerfect(5), marginHorizontal: appTheme.pixelPerfect(5), justifyContent: "center", alignItems: "center" }}>
                                <SVGView Component={IcPlus} size={appTheme.pixelPerfect(12)} color={appTheme.white} />
                            </TouchableOpacity>
                        </View>
                        <Divider />
                        <Button title={"Ajouter à la liste"} onPress={createCodes} />
                    </View>
                </View>
                <View style={{ width: listWidth, flex: 1, alignItems: "center" }}>
                    <View style={{ flex: 1, justifyContent: "flex-start" }}>
                        <View style={{ flex: 1, justifyContent: "flex-start", backgroundColor: "#F" }}>
                            <FlatList
                                ListEmptyComponent={() => {
                                    return <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                        <Image source={require("../../assets/gif/joey_thinking.gif")} style={{ opacity: 0.7, width: appTheme.pixelPerfect(200), height: appTheme.pixelPerfect(200) }} resizeMode="contain" />
                                        <Divider />
                                        <Text style={[pStyle, { textAlign: "center", color: "#BDBDBD" }]}>Aucun code actuellement dans la liste !</Text>
                                    </View>
                                }}
                                data={Object.keys(codes)}
                                style={{ width: listWidth, paddingHorizontal: appTheme.pixelPerfect(20) }}
                                keyExtractor={(item, index) => item.code}
                                renderItem={codeCell} />
                        </View>
                    </View>
                </View>
            </View>
        </View>
    }

    return <View>
        <View style={{ flexDirection: "row", marginBottom: appTheme.pixelPerfect(10) }}>
            <TouchableOpacity onPress={() => goBack()}>
                <Text style={[aStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                    Dashboard
                </Text>
            </TouchableOpacity>
            <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                {">"}
            </Text>
            <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                Carte PREMIUM
            </Text>
        </View>
        {getContent()}
        <Alert ref={alertRef} />
    </View>
}

export default LHQCreateCode;