import { CONFIG } from "./model";

const config: CONFIG = {
    description:"App de prod qui point vers la base de prod",
    isProduction: true,
    appName: "LILI",
    bundleId: "cool.lili.app",
    packageName: "fr.aotb.lili",
    appLabel: "prod",
    theme: {
        primaryColor: "#FF855F",
        secondaryColor: "#404040"
    }
}

export default config;