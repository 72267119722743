import React, { useEffect, useState } from "react";

import {
    View,
    Text,
    Dimensions,
    Platform,
    Image,
    TouchableOpacity
} from "react-native";
import AppTheme from "../../utils/Theme";
import Style from "../../utils/Style";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { isConnnected, signOut } from "../../services/Auth";
import LHQAuth from "./LHQAuth";
import LHQAdminDashboard from "./LHQAdminDashboard";
import LHQTeamLeaderDashboard from "./LHQTeamLeaderDashboard";
import LHQCreateCode from "./LHQCreateCode";
import LHQDocumentation from "./LHQDocumentation";
import LHQActivitiesManagement from "./LHQActivitiesManagement";
import LHQCategoriesManagement from "./LHQCategoriesManagement"
import LHQUserManagement from "./LHQUserManagement";
import LHQNewsManagement from "./LHQNewsManagement"
import User from "../../data/user/User";
import { getUserWithId } from "../../services/Database";
import SVGIconButton from "../../designSystem/Buttons/SVGIconButton";
import { default as IcLogOut } from "../../assets/svg/lili/ic_logout.svg";
import { activitiesListener, app, categoriesListener, premiumCardListener, newsListener } from "../../../specific/services/Specific_Database";

type AuthData = {
    email: string | null | undefined,
    uid: string | undefined
}

interface LHQHomeProps {

}

const isSmallScreenDisplay: boolean = Dimensions.get("window").width > 1024 ? false : true;
export const menuWidth: number = isSmallScreenDisplay === false ? Dimensions.get("window").width * 0.15 : 100;

const LHQHome = (props: LHQHomeProps) => {

    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [authData, setAuthData] = useState<AuthData | null>(null);
    const [isUserConnected, setIsUserConnected] = useState<boolean>(false);

    const [displayPage, setDisplayPage] = useState<string>("DASHBOARD");

    const onViewDidAppear = () => {
        checkUserConnexion();
    }

    useEffect(() => {
        if (authData !== null) {
            if ((authData.email.length > 0) && (authData.uid.length > 0)) {
                checkUserData();
            } else {
                setIsUserConnected(false);
                setViewDidAppear(true);
            }
        }
    }, [authData]);

    useEffect(() => {
        if (isUserConnected === true) {
            // On va récupérer les données de firebase et les stocker en sessions
            if (user.admin === true) {
                getData();
            } else {
                onLogoutRequest();
            }
        }
    }, [isUserConnected]);

    const getData = async () => {
        await categoriesListener();
        await activitiesListener();
        await premiumCardListener();
        await newsListener();
        setViewDidAppear(true);
    }

    const checkUserConnexion = async () => {
        const userAuthData: AuthData | null = await isConnnected();
        if ((userAuthData !== null) && (userAuthData.uid !== undefined)) {
            setAuthData(userAuthData);
        } else {
            setAuthData({ email: "", uid: "" });
        }
    }

    const checkUserData = async () => {
        const offlineUserData = await user.initUserData(authData);
        if (offlineUserData === undefined) {
            onLogoutRequest();
        } else {
            const dbUSerData = await getUserData();
            if (dbUSerData === undefined) {
                onLogoutRequest();
            } else {
                setIsUserConnected(true);
            }
        }
    }

    const getUserData = async () => {
        try {
            // On récupère les informations du profil utilisateur
            const userData = await getUserWithId({ user_id: authData?.uid });
            if (userData === undefined) {
                return undefined;
            }
            await user.setDBUserData(userData);
            return userData;
        } catch (error) {
            return undefined;
        }
    }

    const onLogoutRequest = async () => {
        await signOut();
        checkUserConnexion();
    }

    interface liliHQVerticalNavBarDataInterface {
        title: string,
        image: string,
        page: string,

    };

    const liliHQVerticalNavBarData: liliHQVerticalNavBarDataInterface[] = [
        {
            title: "Accueil",
            image: require("../../assets/img/gunther.png"),
            page: "DASHBOARD"
        },
        {
            title: "Gestion des utilisateurs",
            image: require("../../assets/img/rachel.jpg"),
            page: "USERS"
        },
        {
            title: "Gestion des catégories",
            image: require("../../assets/img/monica.jpg"),
            page: "CATEGORIES"
        },
        {
            title: "Gestion des activités",
            image: require("../../assets/img/phoebe.jpg"),
            page: "ACTIVITIES"
        },
        {
            title: "Gestion des cartes premium",
            image: require("../../assets/img/joey.jpg"),
            page: "CODES"
        },
        {
            title: "Gestion des news",
            image: require("../../assets/img/joey.jpg"),
            page: "NEWS"
        },
        {
            title: "Un peu de documentation",
            image: require("../../assets/img/ross.jpg"),
            page: "DOCUMENTATION"
        },
    ]

    const verticalBavBarCommandes = liliHQVerticalNavBarData.map((commande, i) => {
        const justifyContent = isSmallScreenDisplay === true ? "center" : "flex-start";
        const categoryTitleView: React.JSX.Element = isSmallScreenDisplay === true ? <View /> : <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10) }}>
            {commande.title}
        </Text>;
        return (
            <TouchableOpacity key={i} onPress={() => setDisplayPage(commande.page)} style={{ justifyContent, alignItems: "center", display: "flex", flexDirection: "row", margin: appTheme.pixelPerfect(10) }}>
                <View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center", backgroundColor: "#FFFFFF" }}>
                    <Image source={commande.image} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30) }} />
                </View>
                {categoryTitleView}
            </TouchableOpacity>
        )
    });

    const getPageComponent = () => {
        switch (displayPage) {
            case "USERS":
                return <LHQUserManagement goBack={() => setDisplayPage("USER")} />;
            case "CATEGORIES":
                return <LHQCategoriesManagement goBack={() => setDisplayPage("DASHBOARD")} />;
            case "ACTIVITIES":
                return <LHQActivitiesManagement goBack={() => setDisplayPage("DASHBOARD")} />;
            case "CODES":
                return <LHQCreateCode goBack={() => setDisplayPage("DASHBOARD")} />;
            case "NEWS":
                return <LHQNewsManagement goBack={() => setDisplayPage("DASHBOARD")} />;
            case "DOCUMENTATION":
                return <LHQDocumentation goBack={() => setPageToDisplay("DASHBOARD")} />;
            case "DASHBOARD":
                return <LHQAdminDashboard />;
            default:
                return <LHQAdminDashboard />;
        }
    };

    const MainContent = () => {
        return getPageComponent();
    }

    const body = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator loading={true} color={appTheme.talk} />
            </View>
        }

        if (isUserConnected === false) {
            return <LHQAuth onConnexionSucceed={checkUserConnexion} />
        }

        if (user.admin === true) {
            const logoutButton = isUserConnected === true ? <View style={{ flexDirection: "row", alignItems: "center" }}>
                <SVGIconButton onPress={onLogoutRequest} Icon={IcLogOut} color={appTheme.talk} buttonSize={appTheme.pixelPerfect(30)} />
                <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8) }}>Déconnexion</Text>
            </View> : <View />;
            return (
                <View style={{ justifyContent: "flex-start" }}>
                    <View style={{
                        marginLeft: menuWidth,
                        width: appTheme.getFullAppWidth() * 0.8,
                        flex: 1,
                        paddingHorizontal: appTheme.pixelPerfect(10)
                    }}>
                        <MainContent />
                    </View>
                    <View style={[Style.shadowed, {
                        backgroundColor: "#FFFFFF"
                        width: menuWidth,
                        height: "100%",
                        top: 0,
                        left: 0,
                        position: 'fixed',
                        justifyContent: "flex-start"
                    }]}>
                        <View style={[{ paddingHorizontal: appTheme.pixelPerfect(10), width: "100%", height: appTheme.pixelPerfect(80), backgroundColor: appTheme.grow, flexDirection: "row", alignItems: "center" }]}>
                            <Image source={require("../../assets/img/logo_central_perk_sd.png")} style={{ width: appTheme.pixelPerfect(80), height: appTheme.pixelPerfect(80) }} />
                            <Text style={{ color: appTheme.white, fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(12) }}>
                                LILI HQ
                            </Text>
                        </View>
                        {verticalBavBarCommandes}
                        <View style={{ position: "absolute", bottom: 0, height: appTheme.pixelPerfect(60), justifyContent: "center", padding: appTheme.pixelPerfect(10) }}>
                            {logoutButton}
                        </View>
                    </View>
                </View>

            )
        };

        if (user.teamLeader === true) {
            return <LHQTeamLeaderDashboard />
        }
        return <Text>Bienvenue au Central Perk</Text>
    };

    return <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight() }}>
        <View style={{ flex: 1, flexDirection: "row" }}>
            {body()}
        </View>
    </View>
}

export default LHQHome;