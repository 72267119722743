import React, { useRef, useState } from "react";

import {
    Image,
    Modal,
    Platform,
    SafeAreaView,
    StyleSheet,
    Text,
    TextInput,
    TextStyle,
    Touchable,
    TouchableOpacity,
    View
} from "react-native";
import AppTheme from "../../utils/Theme";

import Main from "../../../specific/components/Main/Main";
import DynamicBackground from "../DynamicBackground/DynamicBackground";
import MainOptions from "../../models/MainOptions";
import Images from "../../../specific/utils/Images";
import User from "../../data/user/User";
import ComponentProps from "../../../specific/utils/Interfaces/ComponentProps";
import Button from "../../designSystem/Button";
import ContactBottomSheet from "./ContactSubjectBottomSheet";
import SVGView from "../../utils/SvgView";
import KeyboardAwareScrollView from "../../../specific/views/KeyboardAwareScrollView";
import { createMessage } from "../../services/AppWriteCRM";

import Alert from "../Notification/Alert";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import { useNavigate } from "react-router-dom";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";
import { IcChevronDown } from "../../utils/Icons";

interface ContactProps extends ComponentProps {

}

type contactMessage = {
    object: string,
    name: string,
    email: string,
    phone_number: string,
    body: string
}

const Contact = (props: ContactProps) => {

    let navigate = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();

    const alertRef = useRef<Alert>(null);

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [showChoices, setShowChoices] = useState<boolean>(false);
    const [messageContent, setMessageContent] = useState<contactMessage>({
        object: "",
        name: "",
        email: user.email,
        phone_number: "",
        body: ""
    });
    const [ticketId, setTicketId] = useState<string>("");

    // MainOptions
    const { componentId = "" } = props;
    const navigationColor = user.isTeacher() === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor;
    const mainOptions: MainOptions = new MainOptions({
        key:"contact",
        componentId,
        navigationColor,
        showNavBar: false,
        showMenu: false,
        canBeHidden: false,
        safeArea: false,
        canGoBack: true,
        backgroundColor: navigationColor,
        canBeDismissed: false
    });

    const onViewDidAppear = () => {

    }

    const toggleSubjectChoices = () => {
        setShowChoices((showChoices) => !showChoices);
    }

    const onObjectChoiceSelected = (choice: string) => {
        onInputTextChange({ key: "object", value: choice });
    }

    const onInputTextChange = (data: { key: string, value: string }) => {
        const { key, value } = data;
        let messageItems: contactMessage = { ...messageContent };
        messageItems[key] = value;
        setMessageContent(messageItems);
    }

    const goBack = () => {
        Navigation.pop({
            componentId,
            navigate
        });
    }

    const sendTheMessage = async () => {
        setInProgress(true);
        try {
            const { object, name, email, body, phone_number } = messageContent;
            await createMessage({
                name,
                object,
                email,
                body,
                phone_number,
                uid: user.uid
            });
            setInProgress(false);
            setMessageContent({
                object: "",
                name: "",
                email: user.email,
                phone_number: "",
                body: ""
            });
            alertRef.current?.showAlert({
                title: "Message envoyé !",
                body: "Notre équipe a bien reçu votre message, et vous recontactera dans les plus brefs délais.",
                onPressPrimaryButton: goBack
            });
        } catch (error) {
            alertRef.current?.showAlert({
                title: "Une erreur est survenue",
                body: "Si cette erreur persiste, vous pouvez nous envoyer un message à : support@lili.cool."
            });
            setInProgress(false);
        }

    }

    const isButtonDisabled = () => {
        if ((messageContent.body.length > 0) && (messageContent.email.length > 0) && (messageContent.name.length > 0) && (messageContent.object.length > 0)) {
            return false;
        }
        return true;
    }

    const textColor = user.isTeacher() === true ? appTheme.darkBlue : appTheme.getLiliAtHomeTitleColor();
    const textInputWidth = Platform.OS === "web" ? (appTheme.getBlockWidth() - appTheme.pixelPerfect(40)) / 2 : appTheme.getBlockWidth();
    const messageObject = messageContent.object.length > 0 ? messageContent.object : "Objet du message";

    const inputStyle: TextStyle = {
        fontSize: appTheme.pixelPerfectForFont(10),
        fontFamily: appTheme.secondaryFont,
        color: appTheme.textColor,
        borderRadius: appTheme.pixelPerfect(18),
        marginVertical: appTheme.pixelPerfect(5),
        padding: appTheme.pixelPerfect(10),
        width: textInputWidth,
        height: appTheme.pixelPerfect(36),
        backgroundColor: "#FFFFFF"
    };

    const getFormFields = () => {
        let formFields = [
            <TextInput
                value={messageContent.name}
                onChangeText={(input) => onInputTextChange({ key: "name", value: input })}
                placeholder={"Nom *"}
                style={inputStyle} />,
            <TextInput
                value={messageContent.email}
                keyboardType="email-address"
                autoCapitalize="none"
                autoCorrect={false}
                onChangeText={(input) => onInputTextChange({ key: "email", value: input })}
                placeholder={"E-mail *"}
                style={inputStyle} />
        ];
        const appTarget = getAppTarget();
        if (appTarget === "public") {
            formFields.push(<TextInput
                value={messageContent.phone_number}
                keyboardType="phone-pad"
                autoCapitalize="none"
                autoCorrect={false}
                onChangeText={(input) => onInputTextChange({ key: "phone_number", value: input })}
                placeholder={"Téléphone"}
                style={inputStyle} />);
        }
        return formFields;
    }

    return <Main mainOptions={mainOptions}>
        <DynamicBackground isTeacher={user.isTeacher()} />
        <KeyboardAwareScrollView>
            <SafeAreaView onLayout={onViewDidAppear} style={{ flex: 1, alignItems: "center" }}>
                <Image source={images.icAbout} style={{ width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(70) }} resizeMode="contain" />
                <Text
                    accessible={true}
                    accessibilityLabel={"Contact"}
                    accessibilityRole="text"
                    style={[{ textAlign: 'center', fontSize: appTheme.pixelPerfectForFont(14), color: textColor }]}>{"Contact".toLocaleUpperCase()}</Text>

                <View style={{ flexDirection: appTheme.getFlexDirectionForSplitScreen(), width: appTheme.getBlockWidth(), justifyContent: "space-evenly", marginTop: appTheme.pixelPerfect(20) }}>
                    <View>
                        <TouchableOpacity onPress={toggleSubjectChoices} style={{ marginBottom: appTheme.pixelPerfect(5), flexDirection: "row", backgroundColor: appTheme.white, height: appTheme.pixelPerfect(36), borderRadius: appTheme.pixelPerfect(18), alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(10) }}>
                            <Text style={{ flex: 1, fontSize: appTheme.pixelPerfectForFont(10), fontFamily: appTheme.secondaryFont, color: appTheme.textColor }}>{messageObject}</Text>
                            <SVGView Component={IcChevronDown} size={appTheme.pixelPerfect(16)} color={appTheme.textColor} />
                        </TouchableOpacity>
                        {getFormFields()}
                    </View>
                    <View>
                        <TextInput
                            multiline={true}
                            value={messageContent.body}
                            onChangeText={(input) => onInputTextChange({ key: "body", value: input })}
                            placeholder={"Message *"}
                            style={[inputStyle, { height: appTheme.pixelPerfect(132) }]} />
                    </View>
                </View>
                <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center" }}>
                    <Text style={{ fontFamily: appTheme.secondaryFont, color: textColor }}>* champs obligatoires</Text>
                </View>
                <Button isLoading={inProgress} disabled={isButtonDisabled()} title="Envoyer" style={{ width: textInputWidth, backgroundColor: appTheme.talk, marginTop: appTheme.pixelPerfect(20) }} onPress={sendTheMessage} />
            </SafeAreaView>
        </KeyboardAwareScrollView>
        <Modal transparent={true} visible={showChoices} animationType="fade">
            <ContactBottomSheet onCloseRequest={toggleSubjectChoices} onChoiceSelected={onObjectChoiceSelected} />
        </Modal>
        <Alert ref={alertRef} />
    </Main>
}

export default Contact;