import React, { useEffect, useState, useRef } from "react";

import {
    Text,
    View,
    SafeAreaView,
    FlatList,
    TouchableOpacity,
    Platform,
    StyleSheet,
    Image,
    Modal,
    ScrollView,
    Alert as RNAlert
} from "react-native";

import Main from "../../../specific/components/Main/Main";
import DynamicBackground from "../DynamicBackground/DynamicBackground";
import MainOptions from "../../models/MainOptions";
import Images from "../../../specific/utils/Images";

import AppTheme from "../../utils/Theme";
import Style from "../../utils/Style";
import Label from "../../utils/Localization/Label";

import i18n from "../../utils/Localization/Localization";

import SVGView from "../../utils/SvgView";
import { default as IcGift } from "../../assets/svg/icons/solid/gift.svg";
import { default as IcUsers } from "../../assets/svg/icons/solid/users.svg";
import { default as IcBug } from "../../assets/svg/icons/solid/bug.svg";
import { default as IcEdit } from "../../assets/svg/icons/solid/edit.svg";
import { default as IcChartLine } from "../../assets/svg/icons/solid/chart-line.svg";
import { default as IcQuoteRight } from "../../assets/svg/icons/solid/quote-right.svg";
import { default as IcOffline } from "../../assets/svg/icons/solid/ic_offline.svg";
import { default as IcStar } from "../../assets/svg/lili/ic_star.svg";

// DATA
import * as Constants from "../../utils/Constants";

// Services
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import { EventRegister } from "../../utils/EventListeners";


import User from "../../data/user/User";
import { useNavigate } from "react-router-dom";
import { getLocalStorage, schoolsPath, setLocalStorage } from "../../../specific/utils/LocalStorage";
import { addDataToUser, addSubToUser } from "../../services/Database";

import Alert from "../Notification/Alert";

import DbUsers from "../../data/bd/db_users.json";
//import ReactNativeBlobUtil from "react-native-blob-util";
import Button from "../../designSystem/Button";
import Divider from "../../designSystem/Divider/Divider";
import TextInput from "../../designSystem/TextInput/RoundCornerTextInput";
import { updateUserForBrevo } from "../../../specific/services/Specific_Database";


const usersCell = {
    id: 'users',
    title: i18n.t('superAdmin.users'),
    icon: IcUsers,
    color: "#FF0066"
};

const giftsCell = {
    id: 'gifts',
    title: i18n.t('superAdmin.gifts'),
    icon: IcGift,
    color: "#FFB000"
};

const citationsCell = {
    id: 'quotes',
    title: i18n.t('superAdmin.quotes'),
    icon: IcQuoteRight,
    color: "#FF0066"
};

const bugsCell = {
    id: 'bug',
    title: i18n.t('superAdmin.bugs'),
    icon: IcBug,
    color: "#FF6600"
};

const teacherProfileModeCell = {
    id: 'teacherProfileMode',
    title: i18n.t('superAdmin.teacherProfileMode'),
    icon: IcEdit,
    color: "#FFB000"
};

const animatorProfileModeCell = {
    id: 'animatorProfileMode',
    title: i18n.t('superAdmin.animatorProfileMode'),
    icon: IcEdit,
    color: "#FFB000"
};

const familyProfileModeCell = {
    id: 'familyProfileMode',
    title: i18n.t('superAdmin.familyProfileMode'),
    icon: IcEdit,
    color: "#FFB000"
};

const editionModeCell = {
    id: 'editionMode',
    title: i18n.t('superAdmin.editionMode'),
    icon: IcEdit,
    color: "#FFB000"
};

const offlineModeCell = {
    id: 'offlineMode',
    title: i18n.t('superAdmin.offlineMode'),
    icon: IcOffline,
    color: "#FFB000"
};

const subModeCell = {
    id: 'sub',
    title: i18n.t('superAdmin.sub'),
    icon: IcStar,
    color: "#FFB000"
};

const tneStat = {
    id: 'tneStats',
    title: i18n.t('superAdmin.tneStat'),
    icon: IcChartLine,
    color: "#2196F3"
};

const bdWork = {
    id: 'bdWork',
    title: "BD WORK",
    icon: IcChartLine,
    color: "#2196F3"
};

const SuperAdmin = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const userInstance: User = User.getInstance();
    const label: Label = new Label();

    const [usersToUpdate, setUsersToUpdate] = useState(undefined);
    const [showUsersList, setShowUsersList] = useState(false);

    const alertRef = useRef<Alert>(null);

    const [viewDidAppear, setViewDidAppear] = useState(false);
    const [isTeacher, setIsTeacher] = useState(userInstance.isTeacher());
    const [user, setUser] = useState(userInstance.getUserData());
    const [editionModeValue, setEditionModeValue] = useState<boolean | undefined>(undefined);
    const [refresh, setRefresh] = useState<number>(0);

    const headerWidth = appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.getFullAppWidth() / 3 : appTheme.getBlockWidth();
    const listWidth = appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.getFullAppWidth() - headerWidth : appTheme.getBlockWidth();

    let menuData = userInstance.admin === true ? [
        [
            giftsCell,
            citationsCell
        ],
        [
            editionModeCell
        ],
        [
            offlineModeCell
        ],
        [
            subModeCell
        ],
        [
            teacherProfileModeCell,
            animatorProfileModeCell,
            familyProfileModeCell,
        ],
        [bdWork]
    ] : userInstance.bizdev === true ? [[
        teacherProfileModeCell,
        animatorProfileModeCell,
        familyProfileModeCell,
    ]] : [];

    // MainOptions
    const { componentId = "" } = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }
    const mainOptions: MainOptions = new MainOptions({
        key: "super_admin",
        componentId,
        showNavBar: false,
        showMenu: false,
        canBeHidden: false,
        safeArea: false,
        canGoBack: true,
        backgroundColor: isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        navigationColor: isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        canBeDismissed: false
    });

    useEffect(() => {
        EventRegister.addEventListener(Constants.EventUserAuthDB, (data) => {
            const userData = userInstance.getUserData();
            setIsTeacher(userData.isTeacher());
        });
        return () => {
            EventRegister.removeEventListener(Constants.EventUserAuthDB);
        };
    }, []);

    useEffect(() => {
        if (usersToUpdate !== undefined) {
            //setShowUsersList(true);
            //setRefresh(new Date().getTime());
            updateUsers(0);
        } else {
            //setShowUsersList(false);
        }
    }, [usersToUpdate]);

    const updateUsers = async (index?: number) => {
        const indextoUpdate: number = index !== undefined ? index : 0;
        // on les mets à jour en remote, et en local, on note qu'ils ont été mise à jour
        console.log("****", indextoUpdate);
        const indexOfKeys = Object.keys(usersToUpdate);
        const aUserKey: string = indexOfKeys[indextoUpdate];
        const aUser = usersToUpdate[aUserKey];
        if ((aUser.toDelete === undefined) || (aUser.toDelete === false)) {
            await updateUserForBrevo(aUserKey, aUser.profile, aUser.school_id);
            let school_id = aUser.school_id !== undefined ?  aUser.school_id : "";
            let brevo_id = ((aUser.schoolStatus !== undefined) && (aUser.schoolStatus.includes("brevo_") === true)) ?  aUser.schoolStatus.replace("brevo_", "") : "";
            updateLogs(aUserKey, aUser.profile, school_id, brevo_id);
        }

        if (indextoUpdate === indexOfKeys.length -1) {
            updateTotalList();
        } else {
            setTimeout(() => {
                updateUsers(indextoUpdate+1);
            }, 1500);
        }
    }

    const updateLogs = async (userId:string, profile:string, userSchoolId:string, schoolBrevoId:string) => {
        /*
        const locaLogsPath: string = ReactNativeBlobUtil.fs.dirs.DocumentDir + "/db_user_logs.json";
        const localFileExists = await ReactNativeBlobUtil.fs.exists(locaLogsPath);
        let localLogs = [];
        if (localFileExists === true) {
            const localLogsAsString: string = await ReactNativeBlobUtil.fs.readFile(locaLogsPath, "utf8");
            localLogs = JSON.parse(localLogsAsString);
        }
        localLogs.push({userId, profile, userSchoolId, schoolBrevoId});
        ReactNativeBlobUtil.fs.writeFile(locaLogsPath, JSON.stringify(localLogs), "utf8");
        */
    }

    const markAsToDelete = (key: string) => {
        let userList = { ...usersToUpdate };
        let aUserToDelete = userList[key];
        if (aUserToDelete.toDelete !== undefined) {
            aUserToDelete.toDelete = !aUserToDelete.toDelete;
        } else {
            aUserToDelete.toDelete = true;
        }
        userList[key] = aUserToDelete;
        //console.log({ userList });
        setUsersToUpdate(userList);
    }

    const changeSchoolIdForUser = (schoolId, userId) => {
        let userList = { ...usersToUpdate };
        let aUserToUpdate = userList[userId];
        aUserToUpdate.school_id = schoolId;
        userList[userId] = aUserToUpdate;
        setUsersToUpdate(userList);
    }

    const renderUserList = () => {
        let userList: React.JSX.Element[] = [];
        for (const aUserKey in usersToUpdate) {
            if (Object.prototype.hasOwnProperty.call(usersToUpdate, aUserKey)) {
                const aUser = usersToUpdate[aUserKey];
                let aUserProfile: string = aUser.profile;
                let isProfileDefined: boolean = true;
                if (aUserProfile === undefined) {
                    isProfileDefined = false;
                    if (aUser.code !== undefined) {
                        aUserProfile = "teacher";
                    } else {
                        aUserProfile = "family";
                    }
                }
                let usersToDelete: React.JSX.Element = <View />;
                if ((aUser.toDelete !== undefined) && (aUser.toDelete === true)) {
                    usersToDelete = <Text style={{ color: "#BB0000" }}>{"A SUPPRIMER"}</Text>;
                }
                let schoolToStudy: React.JSX.Element = <View />;
                if (aUser.schoolStatus !== undefined) {
                    //const schoolStatus: string = aUser.schoolStatus === "no_school" ? "SANS ECOLE" : aUser.schoolStatus.include("brevo_") ? "ID BREVO : "+aUser.schoolStatus.replace("brevo_", "") : "SANS ID BREVO ";
                    const definedSchoolId: React.JSX.Element = aUser.schoolStatus === "no_school" ? <View /> : <Text style={{ color: "#BB0000" }}>{aUser.school_id}</Text>;
                    const schoolId: React.JSX.Element = aUser.schoolStatus === "no_school" ? <View /> : <TextInput onChangeText={(text) => changeSchoolIdForUser(text, aUserKey)} value={aUser.school_id !== undefined ? aUser.school_id : aUser.schoolStatus} />;
                    schoolToStudy = <View>
                        <Text style={{ color: "#BB0000" }}>{aUser.schoolStatus}</Text>
                        {schoolId}
                        {definedSchoolId}
                    </View>
                }
                userList.push(<TouchableOpacity onPress={() => markAsToDelete(aUserKey)} style={{ marginBottom: 10, marginHorizontal: 10, borderWidth: 1, borderRadius: 10, padding: 10 }}>
                    <Text style={{ color: "#010101" }}>{aUser.email}</Text>
                    <Text style={{ color: "#010101" }}>{aUserKey}</Text>
                    <Text style={{ color: isProfileDefined === true ? "#010101" : "#900000" }}>{aUserProfile}</Text>
                    {usersToDelete}
                    {schoolToStudy}
                </TouchableOpacity>)
            }
        }
        return <SafeAreaView style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), backgroundColor: "#FFF" }}>
            <Button title="Fermer" onPress={() => setUsersToUpdate(undefined)} />
            <Divider />
            <Button title="Mettre à jour" onPress={() => updateUsers(0)} />
            <Divider />
            <ScrollView>
                {userList}
            </ScrollView>
        </SafeAreaView>
    }

    let updateTotalList = async () => {
        /*
        const localDbUserPath: string = ReactNativeBlobUtil.fs.dirs.DocumentDir + "/local_db_users.json";
        const localFileExists = await ReactNativeBlobUtil.fs.exists(localDbUserPath);
        let userData = {};
        if (localFileExists === true) {
            const userDataAsString: string = await ReactNativeBlobUtil.fs.readFile(localDbUserPath, "utf8");
            userData = JSON.parse(userDataAsString);
        } else {
            userData = DbUsers;
        }
        for (const aUserKey in usersToUpdate) {
            if (Object.prototype.hasOwnProperty.call(usersToUpdate, aUserKey)) {
                let aUser = usersToUpdate[aUserKey];
                aUser.updatedForBrevo = true;
                userData[aUserKey] = aUser;
            }
        }
        await ReactNativeBlobUtil.fs.writeFile(localDbUserPath, JSON.stringify(userData), "utf8");
        RNAlert.alert("ALL DONE");
        */
    }

    const workOnDB = async () => {
        /*
        console.log("DBWORK");
        // Fichier école
        const schoolDataAsString: string = await ReactNativeBlobUtil.fs.readFile(schoolsPath, "utf8");
        const schoolData = JSON.parse(schoolDataAsString);
        // On va d'abord copier le fichier des utilisateurs en local s'il n'existe pas
        const localDbUserPath: string = ReactNativeBlobUtil.fs.dirs.DocumentDir + "/local_db_users.json";
        const localFileExists = await ReactNativeBlobUtil.fs.exists(localDbUserPath);
        let userData = {};
        if (localFileExists === true) {
            const userDataAsString: string = await ReactNativeBlobUtil.fs.readFile(localDbUserPath, "utf8");
            userData = JSON.parse(userDataAsString);
        } else {
            userData = DbUsers;
        }
        let nbOfTeachers: number = 0;
        let nbOfFamily: number = 0;
        let nbOfAnimators: number = 0;
        let nbOfUnknown: number = 0;
        let nbOfUnknownWithCode: number = 0;
        let userListToUpdate = {};
        for (const aUserKey in userData) {
            if (Object.prototype.hasOwnProperty.call(userData, aUserKey)) {
                let aUser = userData[aUserKey];
                if ((aUser.updatedForBrevo === undefined) && ((aUser.toDelete === undefined) || ((aUser.toDelete !== undefined) && (aUser.toDelete === false)))) {
                    let isTeacher: boolean = false;
                    if (aUser.profile !== undefined) {
                        if (aUser.profile === "teacher") {
                            isTeacher = true;
                            nbOfTeachers++;
                        } else if (aUser.profile === "animator") {
                            nbOfAnimators++;
                        } else {
                            nbOfFamily++;
                        }
                    } else {
                        nbOfUnknown++;
                        if (aUser.code !== undefined) {
                            aUser.profile = "teacher";
                            isTeacher = true;
                            nbOfUnknownWithCode++;
                        } else {
                            aUser.profile = "family";
                        }
                    }
                    if (isTeacher === true) {
                        if (aUser.school_id !== undefined) {
                            if (schoolData[aUser.school_id] !== undefined) {
                                if (schoolData[aUser.school_id].brevo_id === undefined) {
                                    aUser.schoolStatus = aUser.school_id;
                                } else {
                                    aUser.schoolStatus = "brevo_"+schoolData[aUser.school_id].brevo_id;
                                }
                            } else {
                                aUser.schoolStatus = "no_school";
                            }
                        }
                    }

                    //userData[aUserKey] = aUser;
                    userListToUpdate[aUserKey] = aUser;
                    if (Object.keys(userListToUpdate).length === 5000) {
                        break;
                    }
                }
            }
        }
        //await ReactNativeBlobUtil.fs.writeFile(localDbUserPath, JSON.stringify(userData), "utf8");
        console.log({ nbOfAnimators, nbOfTeachers, nbOfFamily, nbOfUnknown, nbOfUnknownWithCode });
        //console.log(userListToUpdate);
        setUsersToUpdate(userListToUpdate);
        */
        /*
            const querySnapshot = await dbSchoolRef.get();
            let userNb = 0;
            let users = {};
            if (querySnapshot.docs.length > 0) {
                for (const anExistingSchoolIndex in querySnapshot.docs) {
                    if (Object.prototype.hasOwnProperty.call(querySnapshot.docs, anExistingSchoolIndex)) {
                        const aDoc = querySnapshot.docs[anExistingSchoolIndex];
                        userNb++;
                        users[aDoc.id] = aDoc.data();
                        console.log(aDoc.id);
                        //await dbSchoolRef.doc(aDoc.id).set({ last_update: new Date().getTime() }, { merge: true });
                        console.log(userNb+" : "+aDoc.id);
                    }
                }
            }
            console.log("record users");
            await ReactNativeBlobUtil.fs.writeFile(usersPath, JSON.stringify(users), "utf8");
            console.log(usersPath);
            //console.log({ users });
            */
    }

    const openItem = async (item: any, isUserSubscriber: boolean) => {
        if (item.id === "sub") {
            let duration: number = 36;
            if (user.isPremium() === true) {
                duration = 0;
            }
            await addSubToUser({ user_id: user.uid, sub_id: "cool.lili.sub.laspremium", duration_in_month: duration, origin: "HQ" });
            setRefresh(new Date().getTime());
        } else if (item.id === "bdWork") {
            // On va proécéder à la mise à jour de la base de données des écoles
            workOnDB();
        } else if ((item.id === "gifts") || (item.id === "tneStats") || (item.id === "quotes") || (item.id === "offlineMode")) {
            let name = "";
            if (item.id === "tneStats") {
                name = Constants.ScreenAdminTNEStats;
            } else if (item.id === "quotes") {
                name = Constants.ScreenAdminQuoteManagement;
            } else if (item.id === "offlineMode") {
                name = Constants.ScreenAdminOfflineManagement;
            }
            if (name.length > 0) {
                Navigation.push({
                    componentId,
                    navigate,
                    name,
                    passProps: {
                    }
                });
            }

        } else if (item.id === "editionMode") {
            try {
                const adminDataString = await getLocalStorage({ key: Constants.keyAdminData });
                let adminData = {};
                if (adminDataString !== null) {
                    adminData = JSON.parse(adminDataString);
                }
                adminData.editionMode = !editionModeValue;
                await setLocalStorage({ key: Constants.keyAdminData, value: JSON.stringify(adminData) });
                setEditionModeValue(!editionModeValue);
            } catch (error) {

            }
        } else if ((item.id === "teacherProfileMode") || (item.id === "animatorProfileMode") || (item.id === "familyProfileMode")) {
            try {
                const newProfile: Constants.UserType = item.id === "teacherProfileMode" ? "teacher" : item.id === "animatorProfileMode" ? "animator" : "family";
                alertRef.current?.showAlert({
                    title: "Changement de profil",
                    body: "Ton profil va être modifié, et l'app relancée !",
                    primaryButtonTitle: "Confirmer",
                    onPressPrimaryButton: () => changeProfile(newProfile),
                    secondaryButtonTitle: "Annuler"
                });
            } catch (error) {
            }
        } else if (item.id === "bug") {
        }
    }

    const changeProfile = async (newProfile: Constants.UserType) => {
        try {
            await addDataToUser({ key: "profile", value: newProfile, user_id: user.uid });
            Navigation.relaunchApp({ componentId, navigate });
        } catch (error) {

        }
    }

    const keyExtractor = (item, index) => {
        return (index.toString());
    }

    const aboutCell = ({ item, index }) => {

        const fullWidthForCell = listWidth - appTheme.pixelPerfect(20);

        let cell = [];
        for (const aCellIndex in item) {
            if (Object.prototype.hasOwnProperty.call(item, aCellIndex)) {
                const aCell = item[parseInt(aCellIndex)];
                const cellWidth = item.length === 1 ? fullWidthForCell : fullWidthForCell / 2 - appTheme.pixelPerfect(5);
                if (aCell.id === "empty") {
                    cell.push(<View style={{ width: cellWidth, marginBottom: appTheme.pixelPerfect(20), justifyContent: 'center', alignItems: 'center', borderRadius: appTheme.pixelPerfect(20), marginHorizontal: appTheme.pixelPerfect(5) }} />);
                } else {
                    let cellHeight = item.length === 1 ? appTheme.pixelPerfect(60) : appTheme.pixelPerfect(100);
                    let horizontalAlign = item.length === 1 ? "flex-start" : "flex-end";
                    let iconStyle = { flex: 1, justifyContent: "center" };
                    let iconColor = aCell.color;
                    if (item.length === 1) {
                        iconStyle = { width: appTheme.pixelPerfect(40), justifyContent: "center" };
                    }
                    const cellFlexDirection = item.length === 1 ? "row" : "column";
                    const cellPaddingTop = item.length === 1 ? appTheme.pixelPerfect(5) : appTheme.pixelPerfect(10);
                    const cellPaddingBottom = item.length === 1 ? appTheme.pixelPerfect(5) : 0;
                    const cellTextAlign = item.length === 1 ? "left" : "center";
                    const cellTextFontSize = item.length === 1 ? appTheme.pixelPerfectForFont(12) : appTheme.pixelPerfectForFont(6);
                    const cellIconSize = item.length === 1 ? appTheme.pixelPerfect(24) : appTheme.pixelPerfect(24);
                    let subtitleView = <View />;
                    let isUserSubscriber = false;
                    const now = new Date().getTime();
                    if (user.sub !== undefined) {
                        let startDate = user.sub.start_date !== undefined ? user.sub.start_date : user.sub.date !== undefined ? user.sub.date : 0;
                        if ((startDate < now) && (user.sub.expires_date > now)) {
                            isUserSubscriber = true;
                        }
                    }
                    let iconSvgView = <SVGView Component={aCell.icon} size={cellIconSize} color={appTheme.white} />;
                    let showCell: boolean = true;
                    if (aCell.id === "sub") {
                        const subDetails: string = user.isPremium() === true ? i18n.t("superAdmin.subOff") : i18n.t("superAdmin.subOn");
                        subtitleView = <View>
                            <Text style={[{ fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10) }]}>{subDetails}</Text>
                        </View>
                    } else if (aCell.id === "editionMode") {
                        cellHeight = appTheme.pixelPerfect(80);
                        const editionModeDescription = editionModeValue === true ? "Mode admin activé" : "Mode admin désactivé";
                        const editionModeActivationExplanation = editionModeValue === true ? "Appuie ici pour le désactiver" : "Appuie ici pour l'activer";
                        iconColor = editionModeValue === false ? appTheme.alertOverlayColor : appTheme.grow;
                        subtitleView = <View>
                            <Text style={[{ fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10) }]}>{editionModeDescription}</Text>
                            <Text style={[{ fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(8) }]}>{editionModeActivationExplanation}</Text>
                        </View>
                    } else if (aCell.id === "teacherProfileMode") {
                        if (user.profile === "teacher") {
                            showCell = false;
                        }
                        iconColor = appTheme.schoolColor;
                        iconSvgView = <Image source={images.icLiliAtSchoolCircle} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />;
                    } else if (aCell.id === "animatorProfileMode") {
                        if (user.profile === "animator") {
                            showCell = false;
                        }
                        iconColor = appTheme.animatorColor;
                        iconSvgView = <Image source={images.icLiliAtExtracurricularCircle} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />;
                    } else if (aCell.id === "familyProfileMode") {
                        if (user.profile === "family") {
                            showCell = false;
                        }
                        iconColor = appTheme.homeColor;
                        iconSvgView = <Image source={images.icLiliAtHomeCircle} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />;
                    }
                    let iconView = <View style={iconStyle}>
                        <View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: iconColor, justifyContent: "center", alignItems: "center" }}>
                            {iconSvgView}
                        </View>
                    </View>;
                    if (showCell === true) {
                        cell.push(
                            <TouchableOpacity
                                accessibilityRole="button"
                                accessible={true}
                                accessibilityLabel={aCell.title}
                                onPress={() => openItem(aCell, isUserSubscriber)}
                                style={[Style.shadowed, { width: cellWidth, justifyContent: "center", alignItems: 'center', borderRadius: appTheme.pixelPerfect(20), marginHorizontal: appTheme.pixelPerfect(5) }]}>
                                <View style={{ flexDirection: cellFlexDirection, width: cellWidth, height: cellHeight, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(20), alignItems: 'center', justifyContent: horizontalAlign, paddingHorizontal: appTheme.pixelPerfect(10), paddingTop: cellPaddingTop, paddingBottom: cellPaddingBottom }}>
                                    {iconView}
                                    <View style={{ padding: appTheme.pixelPerfect(10), justifyContent: 'center' }}>
                                        <Text style={{ textAlign: cellTextAlign, fontFamily: appTheme.secondaryFont, fontSize: cellTextFontSize, color: appTheme.darkBlue }}>
                                            {aCell.title.toLocaleUpperCase()}
                                        </Text>
                                        {subtitleView}
                                    </View>
                                </View>
                            </TouchableOpacity>
                        )
                    }

                }
            }
        }
        return <View style={{ flexDirection: "row", width: listWidth, justifyContent: "center", alignItems: "center", marginBottom: appTheme.pixelPerfect(10) }}>
            {cell}
        </View>;
    }

    const onViewDidAppear = async () => {
        // On va récupérer la valeur du mode édition
        try {
            const adminDataString = await getLocalStorage({ key: Constants.keyAdminData });
            if (adminDataString === null) {
                setEditionModeValue(false);
            } else {
                const adminData = JSON.parse(adminDataString);
                if (adminData.editionMode !== undefined) {
                    setEditionModeValue(adminData.editionMode);
                } else {
                    setEditionModeValue(false);
                }
            }
        } catch (error) {
            setEditionModeValue(false);
        }
    }

    useEffect(() => {
        if (editionModeValue !== undefined) {
            setViewDidAppear(true);
        }
    }, [editionModeValue])

    const mainViewAlignItems = appTheme.getFlexDirectionForSplitScreen() === "row" ? "flex-start" : "center";

    return (
        <Main mainOptions={mainOptions}>
            <DynamicBackground />
            <SafeAreaView onLayout={onViewDidAppear} style={{ flex: 1, alignItems: "center" }}>
                <View style={{ flex: 1, alignItems: mainViewAlignItems, width: appTheme.getFullAppWidth(), paddingTop: appTheme.pixelPerfect(30), flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                    <View style={{ width: headerWidth, justifyContent: "flex-start", alignItems: "center" }}>
                        <Image source={images.illSupercatStanding} style={{ width: appTheme.pixelPerfect(100), height: appTheme.pixelPerfect(100) }} resizeMode="contain" />
                        <Text
                            accessible={true}
                            accessibilityLabel={i18n.t("superAdmin.title")}
                            accessibilityRole="text"
                            style={[{ textAlign: 'center', fontSize: appTheme.pixelPerfectForFont(14) }, { paddingTop: appTheme.pixelPerfect(10), color: appTheme.darkBlue }]}>{i18n.t("superAdmin.title").toLocaleUpperCase()}</Text>
                    </View>
                    <FlatList
                        data={menuData}
                        renderItem={aboutCell}
                        keyExtractor={keyExtractor}
                        style={{ flex: 1, width: listWidth, marginTop: appTheme.pixelPerfect(10) }} />
                </View>
            </SafeAreaView>
            <Alert ref={alertRef} />
            <Modal
                visible={showUsersList}
                animationType="slide">
                {renderUserList()}
            </Modal>
        </Main>
    )
}

export default SuperAdmin;

const styles = StyleSheet.create({
    logoutButton: {
        justifyContent: "center",
        alignItems: "center"
    }
});